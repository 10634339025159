import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, forkJoin } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { DocumentType } from 'src/app/dtos/DocumentType';
import { FinancialInfoRequest } from 'src/app/dtos/FinancialInfoRequest';
import { PersonalInfoRequest } from 'src/app/dtos/PersonalInfoRequest';
import { UploadFileResponse } from 'src/app/dtos/UploadFileResponse';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { FinancialInfoState } from 'src/app/states/FinancialInfoState';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { CountryUtils } from 'src/app/utils/CountryUtils';
import { LegitimationInfoState } from 'src/app/states/LegitimationInfoState';
import { LegitimationInfoRequest } from 'src/app/dtos/LegitimationInfoRequest';
import { IdType } from 'src/app/dtos/IdType';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { Condition } from 'src/app/dtos/Condition';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { Occupation } from 'src/app/dtos/Occupation';
import { Additional } from 'src/app/dtos/Additional';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Select(PersonalInfoState.getPersonalInfo)
  getPersonalInfo$!: Observable<PersonalInfoRequest>;
  personalInfo: PersonalInfoRequest = {};

  @Select(ContactInfoState.getContactInfo)
  getContactInfo$!: Observable<ContactInfoRequest>;
  contactInfo: ContactInfoRequest = {};

  @Select(LegitimationInfoState.getLegitimationInfo)
  getLegitimationInfo$!: Observable<LegitimationInfoRequest>;
  legitimationInfo: LegitimationInfoRequest = {};

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(FinancialInfoState.getFinancialInfo)
  getFinancialInfo$!: Observable<FinancialInfoRequest>;
  financialInfo: FinancialInfoRequest = {};

  @Select(BankInfoState.getBankInfo)
  getBankInfo$!: Observable<BankInfoRequest>;
  bankInfo: BankInfoRequest = {};

  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;
  selectedProduct: ProductResponse = {};

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  backendError: boolean = false;
  uploadForm: FormGroup = {} as FormGroup;
  passportFiles: File[] = [];
  invalidPassportFileType: boolean = false;
  showNonAtControls: boolean = false;
  showNonEuControls: boolean = false;
  showBankGuarantee: boolean = false;
  showSurety: boolean = false;
  invalidSuretyFileType: boolean = false;
  invalidResidencePermitFileType: boolean = false;
  residencePermitFile: File | null = null;
  suretyFile: File | null = null;
  invalidCurrentRegistrationFileType: boolean = false;
  currentRegistrationFile: File | null = null;
  invalidBankGuaranteeFileType: boolean = false;
  bankGuaranteeFile: File | null = null;
  label: string = '';
  passportType: DocumentType = DocumentType.PASSPORT;
  showGuaranteeText: boolean = false;
  passportChanged: boolean = false;
  guaranteeChanged: boolean = false;
  residenceChanged: boolean = false;
  suretyChanged: boolean = false;
  allowedProductList: any[] = [];

  allowedTypes: string[] = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.FILE_UPLOAD;
    this.updateAppState$.emit(this.appState);
    this.getSelectedProduct$.subscribe(
      (value) => (this.selectedProduct = value)
    );
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getFinancialInfo$.subscribe((value) => (this.financialInfo = value));
    this.getPersonalInfo$.subscribe((value) => (this.personalInfo = value));
    this.getContactInfo$.subscribe((value) => (this.contactInfo = value));
    this.getBankInfo$.subscribe((value) => (this.bankInfo = value));
    this.getLegitimationInfo$.subscribe(
      (value) => (this.legitimationInfo = value)
    );

    if (
      this.legitimationInfo.idType === IdType.CONVENTION_PASSPORT ||
      this.legitimationInfo.idType === IdType.PASSPORT
    ) {
      this.label = 'Reisepass';
      this.passportType = DocumentType.PASSPORT;
    }

    if (
      (this.legitimationInfo.idType === IdType.DRIVER_LICENSE ||
        this.legitimationInfo.idType === IdType.DRIVER_LICENSE_15) &&
      this.personalInfo.citizenship === 'AT'
    ) {
      this.label = 'Österreichischer Führerschein';
      this.passportType = DocumentType.DRIVER_LICENSE;
    }

    if (
      this.legitimationInfo.idType === IdType.ID_CARD &&
      CountryUtils.isEuCountry(this.personalInfo.citizenship!)
    ) {
      this.label = 'Personalausweis';
      this.passportType = DocumentType.ID_CARD;
    }

    this.uploadForm = this.formBuilder.group({
      passport: [null, [Validators.required]],
      residencePermit: [null, []],
      surety: [null, []],
      //currentRegistration: [null, []],
      bankGuarantee: [null, []],
    });

    if (this.personalInfo.citizenship != 'AT') {
      /*       const currentRegistration =
        this.uploadForm.controls['currentRegistration'];

      currentRegistration.setValidators([Validators.required]);
      currentRegistration.updateValueAndValidity(); */
      this.showNonAtControls = true;
    }

    if (!CountryUtils.isEuCountry(this.personalInfo.citizenship!)) {
      const residencePermit = this.uploadForm.controls['residencePermit'];
      residencePermit.setValidators([Validators.required]);
      residencePermit.updateValueAndValidity();
      this.showNonEuControls = true;
    }

    if (this.checkIfSuretyIsNeeded()) {
      const surety = this.uploadForm.controls['surety'];
      surety.setValidators([Validators.required]);
      surety.updateValueAndValidity();
      this.showSurety = true;
    }

    this.api
      .getActiveProducts(
        this.personalInfo.birthday!,
        this.financialInfo.occupation!,
        this.financialInfo.income!,
        this.financialInfo.industry!,
        this.financialInfo.workingIncome!
      )
      .subscribe({
        next: (response: ProductResponse[]) => {
          this.allowedProductList = response;
          if (this.checkIfBankGuaranteeIsNeeded()) {
            //const bankGuarantee = this.uploadForm.controls['bankGuarantee'];
            //bankGuarantee.setValidators([Validators.required]);
            //bankGuarantee.updateValueAndValidity();
            this.showBankGuarantee = true;
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  checkIfBankGuaranteeIsNeeded(): boolean {
    if (
      this.personalInfo.birthday == undefined ||
      this.contactInfo.livingCountry == undefined ||
      this.financialInfo.occupation == undefined
    ) {
      return true;
    }

    if (
      this.financialInfo.occupation == Occupation.JOBLESS ||
      this.financialInfo.occupation == Occupation.ON_LEAVE
    ) {
      return true;
    }

    if (
      this.financialInfo.occupation == Occupation.EMPLOYEE ||
      this.financialInfo.occupation == Occupation.WORKER ||
      this.financialInfo.occupation == Occupation.OFFICIAL_SERVICE ||
      this.financialInfo.occupation == Occupation.SELF_EMPLOYED
    ) {
      if (this.financialInfo.employedSince == undefined) {
        return true;
      }

      let threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      if (new Date(this.financialInfo.employedSince) > threeMonthsAgo) {
        return true;
      }
    }

    if (this.financialInfo.occupation != Occupation.STUDENT) {
      if (this.financialInfo.employerCountry == undefined) {
        return true;
      }

      if (!CountryUtils.isDach(this.financialInfo.employerCountry)) {
        return true;
      }
    }

    const currentDate = new Date();

    if (
      this.selectedProduct.additional != Additional.PARTNER ||
      (this.selectedProduct.additional == Additional.PARTNER &&
        this.bankInfo.otherSettlementAccount == true)
    ) {
      if (
        this.bankInfo.accountOk == null ||
        this.bankInfo.accountSince == null
      ) {
        return true;
      }
      if (this.bankInfo.accountOk == false) {
        return true;
      }
      const maxAccountSinceDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );

      const accountSinceString = this.bankInfo.accountSince!;
      const accountSince = new Date(accountSinceString);
      accountSince.setHours(0);

      if (accountSince > maxAccountSinceDate) {
        return true;
      }
    }

    const maxCustomerBirthday = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const birthdayString = this.personalInfo.birthday!;
    const birthdayDate = new Date(birthdayString);
    birthdayDate.setHours(0);

    /*     if (birthdayDate > maxCustomerBirthday) {
      return true;
    } */

    const minCustomerBirthday = new Date(
      currentDate.getFullYear() - 85,
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );

    if (birthdayDate < minCustomerBirthday) {
      return true;
    }

    if (this.contactInfo.livingCountry != 'AT') {
      return true;
    }

    if (
      this.selectedProduct.condition == Condition.STUDENT &&
      this.financialInfo.income! < 500
    ) {
      return true;
    }

    if (
      this.selectedProduct.cup == undefined ||
      this.selectedProduct.selectedDrawLimit == undefined ||
      this.selectedProduct.freeDrawLimit
    ) {
      return true;
    }

    if (!this.isCupAllowed(this.selectedProduct.cup)) {
      return true;
    }

    if (
      !this.getAllowedDrawLimitsFromCup(this.selectedProduct.cup).includes(
        this.selectedProduct.selectedDrawLimit
      )
    ) {
      return true;
    }

    if (this.personalInfo.citizenship != 'AT') {
      if (this.personalInfo.inAtSince == undefined) {
        return true;
      }
      let today = new Date();
      let oneYearAgo = new Date(today);
      let inAtSince = new Date(this.personalInfo.inAtSince);
      oneYearAgo.setFullYear(today.getFullYear() - 1);

      if (inAtSince > oneYearAgo) {
        return true;
      }
    }

    return false;
  }

  onPassportFileChange(event: any) {
    this.passportChanged = true;
    this.passportFiles = [];
    this.invalidPassportFileType = false;

    for (var i = 0; i < event.target.files.length; i++) {
      if (!this.allowedTypes.includes(event.target.files[i].type)) {
        this.invalidPassportFileType = true;
        break;
      } else {
        this.passportFiles[i] = event.target.files[i];
      }
    }
  }

  onBankGuaranteeFileChange(event: any) {
    this.bankGuaranteeFile = null;
    this.invalidBankGuaranteeFileType = false;
    this.guaranteeChanged = true;

    if (event.target.files[0] == null) {
      return;
    }

    if (!this.allowedTypes.includes(event.target.files[0].type)) {
      this.invalidBankGuaranteeFileType = true;
    } else {
      this.bankGuaranteeFile = event.target.files[0];
    }
  }

  onSuretyFileChange(event: any) {
    this.suretyFile = null;
    this.invalidSuretyFileType = false;
    this.suretyChanged = true;

    if (event.target.files[0] == null) {
      return;
    }

    if (!this.allowedTypes.includes(event.target.files[0].type)) {
      this.invalidSuretyFileType = true;
    } else {
      this.suretyFile = event.target.files[0];
    }
  }

  onResidencePermitFileChange(event: any) {
    this.residencePermitFile = null;
    this.invalidResidencePermitFileType = false;
    this.residenceChanged = true;

    if (event.target.files[0] == null) {
      return;
    }

    if (!this.allowedTypes.includes(event.target.files[0].type)) {
      this.invalidResidencePermitFileType = true;
    } else {
      this.residencePermitFile = event.target.files[0];
    }
  }

  /*   onCurrentRegistrationFileChange(event: any) {
    this.currentRegistrationFile = null;
    this.invalidCurrentRegistrationFileType = false;

    if (event.target.files[0] == null) {
      return;
    }

    if (!this.allowedTypes.includes(event.target.files[0].type)) {
      this.invalidCurrentRegistrationFileType = true;
    } else {
      this.currentRegistrationFile = event.target.files[0];
    }
  } */

  onSubmit(): void {
    let tasks: Observable<UploadFileResponse>[] = [];

    for (var i = 0; i < this.passportFiles.length; i++) {
      tasks.push(
        this.api.uploadFile(
          this.currentApplication.applicationId!,
          this.passportFiles[i],
          this.passportType
        )
      );
    }

    /*     if (this.personalInfo.citizenship !== 'AT') {
      tasks.push(
        this.api.uploadFile(
          this.currentApplication.applicationId!,
          this.currentRegistrationFile!,
          DocumentType.CURRENT_REGISTRATION.toString()
        )
      );
    } */

    if (!CountryUtils.isEuCountry(this.personalInfo.citizenship!)) {
      tasks.push(
        this.api.uploadFile(
          this.currentApplication.applicationId!,
          this.residencePermitFile!,
          DocumentType.RESIDENCE_PERMIT.toString()
        )
      );
    }

    if (this.checkIfSuretyIsNeeded()) {
      tasks.push(
        this.api.uploadFile(
          this.currentApplication.applicationId!,
          this.suretyFile!,
          DocumentType.SURETY.toString()
        )
      );
    }

    if (
      this.checkIfBankGuaranteeIsNeeded() &&
      this.bankGuaranteeFile !== null
    ) {
      tasks.push(
        this.api.uploadFile(
          this.currentApplication.applicationId!,
          this.bankGuaranteeFile!,
          DocumentType.BANK_GUARANTEE.toString()
        )
      );
    }

    forkJoin(tasks).subscribe({
      complete: () => {
        this.router.navigateByUrl('summary');
      },
      error: (error) => {
        this.backendError = true;
        console.log(error);
      },
    });
  }

  back(): void {
    this.router.navigateByUrl('service-info');
  }

  isCupAllowed(cup: string): boolean {
    let helper: any[] = this.allowedProductList;
    let isAllowed: boolean = false;
    helper.forEach((i) => {
      if (i.cup == cup) {
        isAllowed = true;
        return;
      }
    });

    return isAllowed;
  }

  getAllowedDrawLimitsFromCup(cup: string): number[] {
    let helper: any[] = this.allowedProductList;
    let drawLimits: number[] = [];
    helper.forEach((i) => {
      if (i.cup == cup) {
        drawLimits = i.drawLimits;
        return;
      }
    });
    return drawLimits;
  }

  checkIfSuretyIsNeeded(): boolean {
    const currentDate = new Date();
    const maxCustomerBirthday = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const birthdayString = this.personalInfo.birthday!;
    const birthdayDate = new Date(birthdayString);
    birthdayDate.setHours(0);

    if (birthdayDate > maxCustomerBirthday) {
      return true;
    }

    return false;
  }
}
