import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { BasicInfoRequest } from '../dtos/BasicInfoRequest';

export const EmptyBasicInfoRequest = {
  ownDemand: undefined,
  pep: undefined,
  activity: undefined,
  function: undefined,
  relationship: undefined,
  firstName: undefined,
  lastName: undefined,
  birthday: undefined,
  nationality: undefined,
};

@State<BasicInfoRequest>({
  name: 'basicInfo',
  defaults: EmptyBasicInfoRequest,
})
@Injectable()
export class BasicInfoState {
  @Selector()
  public static getBasicInfo(state: BasicInfoRequest): BasicInfoRequest {
    return state;
  }
  @Receiver()
  public static updateBasicInfo(
    { getState, patchState }: StateContext<BasicInfoRequest>,
    { payload }: EmitterAction<BasicInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
