import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { ApplicationDetailResponse } from 'src/app/dtos/ApplicationDetailResponse';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { ApplicationState } from 'src/app/dtos/ApplicationState';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import { BasicInfoRequest } from 'src/app/dtos/BasicInfoRequest';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { FinancialInfoRequest } from 'src/app/dtos/FinancialInfoRequest';
import { LegitimationInfoRequest } from 'src/app/dtos/LegitimationInfoRequest';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { PersonalInfoRequest } from 'src/app/dtos/PersonalInfoRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { ServiceInfoRequest } from 'src/app/dtos/ServiceInfoRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { StateService } from 'src/app/services/state.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { BasicInfoState } from 'src/app/states/BasicInfoState';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import {
  CurrentErrorState,
  ErrorModel,
} from 'src/app/states/CurrentErrorState';
import { FinancialInfoState } from 'src/app/states/FinancialInfoState';
import { LegitimationInfoState } from 'src/app/states/LegitimationInfoState';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { ServiceInfoState } from 'src/app/states/ServiceInfoState';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private router: Router,
    private stateService: StateService,
    private keycloak: KeycloakService,
    private route: ActivatedRoute
  ) {}

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;
  @Emitter(BasicInfoState.updateBasicInfo)
  updateBasicInfo$!: Emittable<BasicInfoRequest>;
  @Emitter(PersonalInfoState.updatePersonalInfo)
  updatePersonalInfo$!: Emittable<PersonalInfoRequest>;
  @Emitter(ContactInfoState.updateContactInfo)
  updateContactInfo$!: Emittable<ContactInfoRequest>;
  @Emitter(BankInfoState.updateBankInfo)
  updateBankInfo$!: Emittable<BankInfoRequest>;
  @Emitter(FinancialInfoState.updateFinancialInfo)
  updateFinancialInfo$!: Emittable<FinancialInfoRequest>;
  backendError: boolean = false;
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  @Emitter(LegitimationInfoState.updateLegitimationInfo)
  updateLegitimationInfo$!: Emittable<LegitimationInfoRequest>;
  @Emitter(PaymentInfoState.updatePaymentInfo)
  updatePaymentInfo$!: Emittable<PaymentInfoRequest>;
  @Emitter(ServiceInfoState.updateServiceInfo)
  updateServiceInfo$!: Emittable<ServiceInfoRequest>;
  @Emitter(SelectedProductState.updateSelectedProduct)
  updateSelectedProduct$!: Emittable<ProductResponse>;
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  showBonus: boolean = false;

  ngOnInit(): void {
    this.renderBonus();
    this.getCurrentError$.subscribe(
      (value) => (this.backendError = value.initFailed!)
    );
    this.getAppState$.subscribe((state) => {
      this.appState = state;
    });
    this.appState.currentScreen = Screen.HOME;
    this.updateAppState$.emit(this.appState);

    const applicationId = this.route.snapshot.queryParamMap.get('id');

    if (applicationId != null && applicationId != undefined) {
      this.stateService.clearAllState();
      this.api.getApplication(applicationId).subscribe({
        next: (response: ApplicationDetailResponse) => {
          if (response.application?.state == ApplicationState.INITIALIZED) {
            this.updateCurrentApplication$.emit(response.application!);
            this.updatePersonalInfo$.emit(response.personalInfo!);
            this.updateContactInfo$.emit(response.contactInfo!);
            this.updateLegitimationInfo$.emit(response.legitimationInfo!);
            this.updateBankInfo$.emit(response.bankInfo!);
            this.updateFinancialInfo$.emit(response.financialInfo!);

            if (response.productInfo != undefined) {
              response.productInfo.selectedDrawLimit =
                response.selectedDrawLimit;
              response.productInfo.selectedFeeCondition =
                response.selectedFeeCondition;
              response.productInfo.freeDrawLimit = response.freeDrawLimit;
              response.productInfo.freeDrawLimitAmount =
                response.freeDrawLimitAmount;
            }

            this.updateSelectedProduct$.emit(response.productInfo!);
            this.updatePaymentInfo$.emit(response.paymentInfo!);
            this.updateBasicInfo$.emit(response.basicInfo!);
            this.updateServiceInfo$.emit(response.serviceInfo!);

            this.router.navigateByUrl('personal-info');
          } else if (
            response.application?.state ==
            ApplicationState.WAITING_FOR_BANK_GUARANTEE
          ) {
            this.updateCurrentApplication$.emit(response.application!);
            this.router.navigateByUrl('guarantee-upload');
          } else {
            this.backendError = true;
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
    }
  }

  renderBonus(): void {
    let startDate = new Date('2024-10-31');
    startDate.setHours(23, 0, 0, 0);
    let endDate = new Date('2024-12-01');
    let now = new Date();

    if (now > startDate && now < endDate) {
      this.showBonus = true;
    }
  }
}
