import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { ServiceInfoRequest } from 'src/app/dtos/ServiceInfoRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { ServiceInfoState } from 'src/app/states/ServiceInfoState';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.scss',
})
export class PreviewComponent implements OnInit {
  constructor(private router: Router, private api: ApiConnectorService) {}

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;
  selectedProduct: ProductResponse = {};

  @Select(ContactInfoState.getContactInfo)
  getContactInfo$!: Observable<ContactInfoRequest>;
  contactInfo: ContactInfoRequest = {};

  @Select(ServiceInfoState.getServiceInfo)
  getServiceInfo$!: Observable<ServiceInfoRequest>;
  serviceInfo: ServiceInfoRequest = {};

  @Select(PaymentInfoState.getPaymentInfo)
  getPaymentInfo$!: Observable<PaymentInfoRequest>;
  paymentInfo: PaymentInfoRequest = {};

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(BankInfoState.getBankInfo)
  getBankInfo$!: Observable<BankInfoRequest>;
  bankInfo: BankInfoRequest = {};

  readMore: boolean = false;
  buttonText: string = 'Mehr lesen';

  backendError: boolean = false;
  errorMessage: string = '';

  ngOnInit(): void {
    this.updateAppState$.emit({ currentScreen: Screen.PREVIEW });
    this.getContactInfo$.subscribe((value) => (this.contactInfo = value));
    this.getSelectedProduct$.subscribe(
      (value) => (this.selectedProduct = value)
    );
    this.getPaymentInfo$.subscribe((value) => (this.paymentInfo = value));
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getServiceInfo$.subscribe((value) => (this.serviceInfo = value));
    this.getBankInfo$.subscribe((value) => (this.bankInfo = value));

    const con = document.getElementById('contract') as HTMLAnchorElement;
    this.api
      .getContractPreview(this.currentApplication.applicationId!)
      .subscribe({
        next: (blob) => {
          con.href = URL.createObjectURL(blob);
        },
        error: (error) => {
          this.backendError = true;
          this.errorMessage = 'Es ist ein Fehler aufgetreten';
          console.log(error);
        },
      });
  }

  toggle(): void {
    if (this.readMore) {
      this.readMore = false;
      this.buttonText = 'Mehr lesen';
    } else {
      this.readMore = true;
      this.buttonText = 'Weniger lesen';
    }
  }

  submit(): void {
    this.router.navigateByUrl('signing');
  }
}
