<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>

  <form [formGroup]="contactInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="livingCountryControl" class="form-label"
        >Wohnsitz Land: *</label
      >
      <ng-select
        id="livingCountryControl"
        placeholder="Bitte das Land auswählen"
        [items]="livingCountries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="livingCountry"
      >
      </ng-select>
      <div
        *ngIf="
          contactInfoForm.controls['livingCountry'].errors &&
          contactInfoForm.controls['livingCountry'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein Land auswählen
      </div>
    </div>
    <div *ngIf="showLivingInAt" class="mb-3">
      <label for="streetControl" class="form-label">Straße *</label>
      <ng-select
        id="streetControl"
        [items]="streetList | async"
        bindLabel="street"
        [editableSearchTerm]="true"
        placeholder="Tippen um zu suchen"
        typeToSearchText="Tippen um zu suchen"
        notFoundText="Keine Einträge gefunden"
        [minTermLength]="2"
        [loading]="streetLoading"
        [typeahead]="streetInput$"
        formControlName="street"
        ><ng-template ng-option-tmp let-item="item">
          <div>
            <span>{{ item.street }},</span><br />
            <small>{{ item.zip }} {{ item.city }}</small>
          </div>
        </ng-template>
      </ng-select>
      <div
        *ngIf="
          contactInfoForm.controls['street'].errors &&
          contactInfoForm.controls['street'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine Straße auswählen auswählen
      </div>
    </div>
    <div *ngIf="showLivingInAt" class="mb-3">
      <label for="houseNumberControl" class="form-label">Hausnummer *:</label>
      <input
        type="text"
        class="form-control"
        id="houseNumberControl"
        placeholder="Bitte die Hausnummer eingeben"
        formControlName="houseNumber"
      />
      <div
        *ngIf="
          contactInfoForm.controls['houseNumber'].errors &&
          contactInfoForm.controls['houseNumber'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Hausnummer eingeben
      </div>
    </div>
    <div *ngIf="showLivingNotInAt" class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="addressline1Control">Adresszeile 1: *</label>
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showAdrressLine1Info = !showAdrressLine1Info"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="showAdrressLine1Info" class="alert alert-info" role="alert">
        Meldeadresse (Straße, Hausnummer etc.)
      </div>
      <input
        type="text"
        class="form-control"
        id="addressline1Control"
        placeholder="Bitte die erste Adresszeile eingeben"
        formControlName="addressLine1"
      />
      <div
        *ngIf="
          contactInfoForm.controls['addressLine1'].errors &&
          contactInfoForm.controls['addressLine1'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Adresszeile eingeben
      </div>
    </div>
    <div *ngIf="showLivingNotInAt" class="mb-3">
      <label for="addressline2Control" class="form-label">Adresszeile 2:</label>
      <input
        type="text"
        class="form-control"
        id="addressline2Control"
        placeholder="Bitte die zweite Adresszeile eingeben"
        formControlName="addressLine2"
      />
      <div
        *ngIf="
          contactInfoForm.controls['addressLine2'].errors &&
          contactInfoForm.controls['addressLine2'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Adresszeile eingeben
      </div>
    </div>
    <div *ngIf="showLivingInAt" class="mb-3">
      <label for="zipATControl" class="form-label">PLZ: *</label>
      <ng-select
        id="zipATControl"
        placeholder="Bitte die PLZ auswählen"
        [items]="zips"
        bindValue="plz"
        bindLabel="plz"
        formControlName="zipAT"
      >
      </ng-select>
      <div
        *ngIf="
          contactInfoForm.controls['zipAT'].errors &&
          contactInfoForm.controls['zipAT'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Postleitzahl eingeben
      </div>
    </div>
    <div *ngIf="showLivingNotInAt" class="mb-3">
      <label for="zipNonATControl" class="form-label">PLZ *:</label>
      <input
        type="text"
        class="form-control"
        id="zipNonATControl"
        placeholder="Bitte die PLZ eingeben"
        formControlName="zipNonAT"
      />
      <div
        *ngIf="
          contactInfoForm.controls['zipNonAT'].errors &&
          contactInfoForm.controls['zipNonAT'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte PLZ eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="placeControl" class="form-label">Ort: *</label>
      <input
        type="text"
        class="form-control"
        id="placeControl"
        placeholder="Bitte den Ort eingeben"
        formControlName="place"
        maxlength="25"
      />
      <div
        *ngIf="
          contactInfoForm.controls['place'].errors &&
          contactInfoForm.controls['place'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen korrekten Ort eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="mobileCountryCodeControl" class="form-label"
        >Ländervorwahl: *</label
      >
      <ng-select
        id="mobileCountryCodeControl"
        placeholder="Bitte die Vorwahl auswählen"
        [items]="countryList"
        bindLabel="displayName"
        bindValue="telPrefix"
        formControlName="mobileCountryCode"
      >
      </ng-select>
      <div
        *ngIf="
          contactInfoForm.controls['mobileCountryCode'].errors &&
          contactInfoForm.controls['mobileCountryCode'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Vorwahl auswählen
      </div>
    </div>

    <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="mobileNumberControl">Mobilnummer: *</label>
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showNumberInfo = !showNumberInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="showNumberInfo" class="alert alert-info" role="alert">
        Die Mobilnummer ohne vorangestellter 0 in folgendem Format eingeben:
        66412345678
      </div>
      <input
        type="text"
        class="form-control"
        id="mobileNumberControl"
        placeholder="Eingabe ohne vorangestellte „0“ Bsp. 6641234567"
        formControlName="mobileNumber"
      />
      <div
        *ngIf="
          contactInfoForm.controls['mobileNumber'].errors &&
          contactInfoForm.controls['mobileNumber'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Mobilnummer eingeben
      </div>
    </div>

    <div class="mb-3">
      <div class="row form-label">
        <div class="col"><label for="emailControl">Email: *</label></div>
        <div class="col-auto text-end">
          <svg
            (click)="shoEmailInfo = !shoEmailInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="shoEmailInfo" class="alert alert-info" role="alert">
        Die angegebene E-Mail-Adresse darf maximal 40 Zeichen lang sein. Das
        automatische Ausfüllen dieses Feldes, z.B. über den Browser, wird nicht
        angenommen. Bitten tippen sie die E-Mail-Adresse ein.
      </div>
      <input
        autocomplete="off"
        onpaste="return false;"
        type="email"
        class="form-control"
        id="emailControl"
        placeholder="Bitte die Email eingeben"
        formControlName="email"
      />
      <div
        *ngIf="
          contactInfoForm.controls['email'].errors &&
          contactInfoForm.controls['email'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine korrekte Email eingeben
      </div>
    </div>

    <div class="mb-3 form-check">
      <div class="row">
        <div class="col">
          <label for="sendToCorrespondenceControl" class="form-check-label"
            >An Korrespondenz Adresse senden</label
          ><input
            type="checkbox"
            class="form-check-input"
            id="sendToCorrespondenceControl"
            formControlName="sendToCorrespondence"
          />
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showCorInfo = !showCorInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div *ngIf="showCorInfo" class="alert alert-info" role="alert">
      Korrespondenzadresse (optional): Falls z.B. der Versand von Karten,
      PIN-Codes und erstellte Papierabrechnungen an diese abweichende Adresse
      erfolgen sollen.
    </div>

    <div
      *ngIf="showCorrespondence"
      class="border border-primary p-3 mb-3 rounded border-3"
    >
      <div class="mb-3">
        <label for="corCountryControl" class="form-label"
          >Korrespondenz Land: *</label
        >
        <ng-select
          id="corCountryControl"
          placeholder="Bitte das Korrespondenz Land auswählen"
          [items]="countries"
          bindLabel="country"
          bindValue="countryCode"
          formControlName="corCountry"
        >
        </ng-select>
        <div
          *ngIf="
            contactInfoForm.controls['corCountry'].errors &&
            contactInfoForm.controls['corCountry'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte ein Land auswählen
        </div>
      </div>
      <div *ngIf="showCorInAt" class="mb-3">
        <label for="corStreetControl" class="form-label"
          >Korrespondenz Straße *</label
        >
        <ng-select
          id="corStreetControl"
          [items]="corStreetList | async"
          bindLabel="street"
          [editableSearchTerm]="true"
          placeholder="Tippen um zu suchen"
          typeToSearchText="Tippen um zu suchen"
          notFoundText="Keine Einträge gefunden"
          [minTermLength]="2"
          [loading]="corStreetLoading"
          [typeahead]="corStreetInput$"
          formControlName="corStreet"
          ><ng-template ng-option-tmp let-item="item">
            <div>
              <span>{{ item.street }},</span><br />
              <small>{{ item.zip }} {{ item.city }}</small>
            </div>
          </ng-template>
        </ng-select>
        <div
          *ngIf="
            contactInfoForm.controls['corStreet'].errors &&
            contactInfoForm.controls['corStreet'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine Straße auswählen auswählen
        </div>
      </div>
      <div *ngIf="showCorInAt" class="mb-3">
        <label for="corHouseNumberControl" class="form-label"
          >Korrespondenz Hausnummer *:</label
        >
        <input
          type="text"
          class="form-control"
          id="corHouseNumberControl"
          placeholder="Bitte die Hausnummer eingeben"
          formControlName="corHouseNumber"
        />
        <div
          *ngIf="
            contactInfoForm.controls['corHouseNumber'].errors &&
            contactInfoForm.controls['corHouseNumber'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Hausnummer eingeben
        </div>
      </div>
      <div *ngIf="showCorNotInAt" class="mb-3">
        <label for="corAddressLine1Control" class="form-label"
          >Korrespondenz Adresszeile 1: *</label
        >
        <input
          type="text"
          class="form-control"
          id="corAddressLine1Control"
          placeholder="Bitte die erste Korrespondenz Adresszeile eingeben"
          formControlName="corAddressLine1"
        />
        <div
          *ngIf="
            contactInfoForm.controls['corAddressLine1'].errors &&
            contactInfoForm.controls['corAddressLine1'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>
      <div *ngIf="showCorNotInAt" class="mb-3">
        <label for="corAddressLine2Control" class="form-label"
          >Korrespondenz Adresszeile 2:</label
        >
        <input
          type="text"
          class="form-control"
          id="corAddressLine2Control"
          placeholder="Bitte die zweite Korrespondenz Adresszeile eingeben"
          formControlName="corAddressLine2"
        />
        <div
          *ngIf="
            contactInfoForm.controls['corAddressLine2'].errors &&
            contactInfoForm.controls['corAddressLine2'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>

      <div *ngIf="showCorInAt" class="mb-3">
        <label for="corZipATControl" class="form-label"
          >Korrespondenz PLZ: *</label
        >
        <ng-select
          id="corZipATControl"
          placeholder="Bitte die Korrespondenz PLZ auswählen"
          [items]="zips"
          bindLabel="plz"
          bindValue="plz"
          formControlName="corZipAT"
        >
        </ng-select>
        <div
          *ngIf="
            contactInfoForm.controls['corZipAT'].errors &&
            contactInfoForm.controls['corZipAT'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Postleitzahl eingeben
        </div>
      </div>

      <div *ngIf="showCorNotInAt" class="mb-3">
        <label for="corZipNonATControl" class="form-label"
          >Korrespondenz PLZ: *</label
        >
        <input
          type="text"
          class="form-control"
          id="corZipNonATControl"
          placeholder="Bitte die Korrespondenz PLZ eingeben"
          formControlName="corZipNonAT"
        />
        <div
          *ngIf="
            contactInfoForm.controls['corZipNonAT'].errors &&
            contactInfoForm.controls['corZipNonAT'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte PLZ eingeben
        </div>
      </div>

      <div class="mb-3">
        <label for="corPlaceControl" class="form-label"
          >Korrespondenz Ort *:</label
        >
        <input
          type="text"
          class="form-control"
          id="corPlaceControl"
          placeholder="Bitte den Korrespondenz Ort eingeben"
          formControlName="corPlace"
          maxlength="25"
        />
        <div
          *ngIf="
            contactInfoForm.controls['corPlace'].errors &&
            contactInfoForm.controls['corPlace'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte einen korrekten Ort eingeben
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="contactInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- living country
{{ contactInfoForm.controls["livingCountry"].errors | json }} street{{
  contactInfoForm.controls["street"].errors | json
}}
houseNumber{{
  contactInfoForm.controls["houseNumber"].errors | json
}}
addressLine1{{
  contactInfoForm.controls["addressLine1"].errors | json
}}
addressLine2{{ contactInfoForm.controls["addressLine2"].errors | json }} zipAT{{
  contactInfoForm.controls["zipAT"].errors | json
}}
zipNonAT{{ contactInfoForm.controls["zipNonAT"].errors | json }} place{{
  contactInfoForm.controls["place"].errors | json
}}
mobileCountryCode{{
  contactInfoForm.controls["mobileCountryCode"].errors | json
}}
mobileNumber{{ contactInfoForm.controls["mobileNumber"].errors | json }} email{{
  contactInfoForm.controls["email"].errors | json
}}
sendToCorrespondence{{
  contactInfoForm.controls["sendToCorrespondence"].errors | json
}}
corStreet{{
  contactInfoForm.controls["corStreet"].errors | json
}}
corHouseNumber{{
  contactInfoForm.controls["corHouseNumber"].errors | json
}}
corAddressLine1{{
  contactInfoForm.controls["corAddressLine1"].errors | json
}}
corAddressLine2{{
  contactInfoForm.controls["corAddressLine2"].errors | json
}}
corPlace{{ contactInfoForm.controls["corPlace"].errors | json }} corCountry{{
  contactInfoForm.controls["corCountry"].errors | json
}}
corZipAT{{ contactInfoForm.controls["corZipAT"].errors | json }} corZipNonAT{{
  contactInfoForm.controls["corZipNonAT"].errors | json
}} -->
