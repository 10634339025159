export enum DocumentType {
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  PASSPORT = 'PASSPORT',
  ID_CARD = 'ID_CARD',
  //CURRENT_REGISTRATION = 'CURRENT_REGISTRATION',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  //INCOME_PROOF = 'INCOME_PROOF',
  BANK_GUARANTEE = 'BANK_GUARANTEE',
  SURETY = 'SURETY',
}

/* export const PassportsForAt = [
  { value: 'DRIVER_LICENSE', label: 'Österreichischer Führerschein' },
  { value: 'PASSPORT', label: 'Reisepass' },
  { value: 'ID_CARD', label: 'Personalausweis' },
];

export const PassportsForEu = [
  { value: 'PASSPORT', label: 'Reisepass' },
  { value: 'ID_CARD', label: 'Personalausweis' },
];

export const PassportForNonEu = [{ value: 'PASSPORT', label: 'Reisepass' }]; */
