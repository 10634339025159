<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>

  <form [formGroup]="serviceInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <b>PayLife Services, Funktionen und Extras:</b>
    </div>

    <div class="alert alert-info mb-3" role="alert">
      <div class="mb-2 form-check">
        <label for="portalControl" class="form-check-label"
          ><b
            >Teilnahme am Serviceportal "myPayLife" und Online Abrechnung in
            Ihrem Serviceportal: myPayLife</b
          ></label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="portalControl"
          formControlName="portal"
        />
      </div>
      Ich möchte das Online Service der PayLife zur Karte nutzen und beauftrage
      Zugang zum Serviceportal "myPayLife". Die Besonderen Geschäftsbedingungen
      für das Serviceportal "myPayLife" wird vereinbart. Vereinbart wird auch,
      dass mir PayLife die Monatsabrechnungen als PDF-Dokument im Serviceportal
      "myPayLife" zugänglich macht; die diesbezüglichen Details sind in Punkt 11
      der Allgemeinen Geschäftsbedingungen für PayLife Kreditkarten geregelt.
    </div>

    <div class="alert alert-info mb-3" role="alert">
      <div class="mb-2 form-check">
        <label for="newsletterControl" class="form-check-label"
          ><b>PayLife Newsletter und Angebote:</b></label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="newsletterControl"
          formControlName="newsletter"
        />
      </div>

      Ich gebe ausdrücklich meine Zustimmung von PayLife Informationen in
      Verbindung mit Produkten und Dienstleistungen (auch für Marketingzwecke)
      mittels Telefonanrufen, Fax und der Zusendung elektronischer Post (E-Mail,
      SMS, Messenger-Services) zu erhalten. Die Zusendung der elektronischen
      Post kann auch für Servicezwecke erfolgen, wobei ich einer Zusendung als
      Massensendung ausdrücklich zustimme. Ich kann diese Zustimmung jederzeit
      widerrufen.
    </div>

    <div class="alert alert-info mb-3" role="alert">
      <div class="form-check mb-2">
        <label for="trxNotificationControl" class="form-check-label">
          <b>Info SMS Service:</b>
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          id="trxNotificationControl"
          formControlName="trxNotification"
        />
      </div>
      <span>{{ trxNotificationText }} </span
      ><span *ngIf="!showTrxSuffix" (click)="showTrxSuffix = !showTrxSuffix">
        ... <b class="clickable-text">Mehr lesen</b></span
      ><span *ngIf="showTrxSuffix">{{ suffixTrxNotificationText }} </span
      ><span (click)="showTrxSuffix = !showTrxSuffix" *ngIf="showTrxSuffix"
        ><b class="clickable-text">Weniger lesen</b></span
      >
    </div>

    <div class="alert alert-info mb-3" role="alert">
      <div class="mb-2 form-check">
        <label for="paperBillControl" class="form-check-label">
          <b>Monatsabrechnung in Papierform:</b>
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          id="paperBillControl"
          formControlName="paperBill"
        />
      </div>
      {{ paperBillText }}
    </div>

    <div class="alert alert-info mb-3" role="alert">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_74_47)">
              <path
                d="M10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99999C1.66699 5.39749 5.39783 1.66666 10.0003 1.66666C14.6028 1.66666 18.3337 5.39749 18.3337 9.99999C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333ZM10.0003 16.6667C11.7684 16.6667 13.4641 15.9643 14.7144 14.714C15.9646 13.4638 16.667 11.7681 16.667 9.99999C16.667 8.23188 15.9646 6.53619 14.7144 5.28594C13.4641 4.0357 11.7684 3.33332 10.0003 3.33332C8.23222 3.33332 6.53652 4.0357 5.28628 5.28594C4.03604 6.53619 3.33366 8.23188 3.33366 9.99999C3.33366 11.7681 4.03604 13.4638 5.28628 14.714C6.53652 15.9643 8.23222 16.6667 10.0003 16.6667ZM9.16699 5.83332H10.8337V7.49999H9.16699V5.83332ZM9.16699 9.16666H10.8337V14.1667H9.16699V9.16666Z"
                fill="#75B8D9"
              />
            </g>
            <defs>
              <clipPath id="clip0_74_47">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="col px-0 d-flex align-items-center">
          <b>Bitte beachten Sie:</b>
        </div>
      </div>

      <div class="mt-2">
        Den PIN-Code Ihrer Kreditkarte können Sie sich in myPayLife anzeigen
        lassen. Einfach online im Serviceportal myPayLife oder in der myPayLife
        App unter "Verwalten" den Punkt „PIN-Code anzeigen“ auswählen.
      </div>

      <!--       <div class="form-check">
        <label for="pinControl" class="form-check-label">
          <b
            >Ich beauftrage die Ausstellung einer persönlichen
            Identifikationsnummer (PIN) und möchte diesen PIN-Code im
            Serviceportal "my.paylife" abrufen.</b
          >
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          id="pinControl"
          formControlName="pin"
        />
      </div> -->

      <!--       <div class="px-4">
        <small><i>Nur bei Bedarf ankreuzen:</i></small>
        <div class="px-4 form-check">
          <label for="paperPinControl" class="form-check-label"
            ><small
              ><i
                >Ich verlange, dass mir der PIN-Code kostenlos in Papierform
                getrennt von der Karte an die von mir angegebene
                (Korrespondenz-)Adresse übermittelt wird.</i
              ></small
            ></label
          >
          <input
            type="checkbox"
            class="form-check-input"
            id="paperPinControl"
            formControlName="paperPin"
          />
        </div>
      </div> -->
    </div>
    <div class="mb-3 form-check">
      <label for="acceptControl" class="form-check-label"
        ><span *ngIf="partnerFlow"
          >Wir bestätigen hiermit die folgenden Informationen und stimmen diesen
          zu:</span
        ><span *ngIf="!partnerFlow"
          >Ich bestätige hiermit die folgenden Informationen und stimme diesen
          zu:</span
        ></label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="acceptControl"
        formControlName="accept"
      />
      <ul>
        <li>
          <span *ngIf="!partnerFlow"
            >Ich bestätige, dass meine Angaben in dieser Erklärung vollständig
            und korrekt sind. Zukünftige Änderungen betreffend der oben
            angeführten Daten werde ich unverzüglich in der vereinbarten
            Kommunikationsform bekannt geben.</span
          >
          <span *ngIf="partnerFlow"
            >Wir bestätigen, dass unsere Angaben in dieser Erklärung vollständig
            und korrekt sind. Zukünftige Änderungen betreffend der oben
            angeführten Daten werden wir unverzüglich in der vereinbarten
            Kommunikationsform bekannt geben.</span
          >
        </li>
        <li>
          <span *ngIf="!partnerFlow"
            >Ich stimme zu, dass mir alle für meinen Vertrag relevanten
            Informationen und Vertragsbedingungen gemäß §§ 47ff ZaDiG 2018 und
            das Preisblatt per E-mail im PDF-Format an meine im Kartenauftrag
            angegebenen E-Mail Adresse übermittelt werden. Ich kann das PDF
            abspeichern bzw. ausdrucken und vor der Unterfertigung des Vertrages
            sorgfältig lesen.</span
          >
          <span *ngIf="partnerFlow"
            >Wir stimmen zu, dass uns alle für unseren Vertrag relevanten
            Informationen und Vertragsbedingungen gemäß §§ 47ff ZaDiG 2018 und
            das Preisblatt per E-mail im PDF-Format an unsere im Kartenauftrag
            angegebenen E-Mail Adressen übermittelt werden. Wir kann das PDF
            abspeichern bzw. ausdrucken und vor der Unterfertigung des Vertrages
            sorgfältig lesen.</span
          >
        </li>
        <li>
          <span *ngIf="!partnerFlow"
            >Ich stimme zu, dass PayLife mit mir bis zum Abschluss des
            Geschäftsfalls per verschlüsselter und passwortgeschützter E-Mail
            auf die von mir in diesem Kartenauftrag bekanntgegebene E-Mail
            Adresse kommuniziert. Bitte beachten Sie die Risiken bei der
            Verwendung von E-Mail und dazu unser Informationsblatt.</span
          >
          <span *ngIf="partnerFlow"
            >Wir stimmen zu, dass PayLife mit uns bis zum Abschluss des
            Geschäftsfalls per verschlüsselter und passwortgeschützter E-Mail
            auf die von uns in diesem Kartenauftrag bekanntgegebenen E-Mail
            Adressen kommuniziert. Bitte beachten Sie die Risiken bei der
            Verwendung von E-Mail und dazu unser Informationsblatt.</span
          >
        </li>
        <li>
          <span *ngIf="!partnerFlow"
            >Ich bin damit einverstanden, dass die Bank zum Zwecke der
            Produkteröffnung meine Daten für die nächsten 28 Tage speichert und
            verwendet.</span
          ><span *ngIf="partnerFlow"
            >Wir sind damit einverstanden, dass die Bank zum Zwecke der
            Produkteröffnung unsere Daten für die nächsten 28 Tage speichert und
            verwendet.</span
          >
        </li>
        <li>
          <span *ngIf="!partnerFlow"
            >Ich bin weder selbst eine politisch exponierte Person, noch deren
            Familienmitglied oder dieser nahe stehend.</span
          >
          <span *ngIf="partnerFlow"
            >Wir sind weder selbst eine politisch exponierte Person, noch deren
            Familienmitglied oder dieser nahe stehend.</span
          >
          Weitere Informationen zum PEP finden Sie
          <a
            href="https://www.paylife.at/paylife/privat/service/ausfuellhilfe"
            target="_blank"
            >hier</a
          >.
        </li>
      </ul>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="serviceInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
