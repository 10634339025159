<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <div><b>Überprüfen Sie die Daten</b></div>
  <h2 class="mt-3">Persönliche Angaben</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>Name: </b>{{ personalInfoRequest.salutation | salutation }}
      {{ personalInfoRequest.academicTitle | academicTitle }}
      {{ personalInfoRequest.occupationTitle | occupationTitle }}
      {{ personalInfoRequest.firstName }} {{ personalInfoRequest.lastName }}
      {{ personalInfoRequest.postposedAcademicTitle | postposedAcademicTitle }}
    </li>
    <li class="list-group-item">
      <b>Geburtsdatum: </b
      >{{ personalInfoRequest.birthday | date : "dd.MM.yyyy" }}
    </li>
    <li class="list-group-item">
      <b>Staatsangehörigkeit: </b>
      {{ personalInfoRequest.citizenship | country }}
    </li>
    <li *ngIf="personalInfoRequest.inAtSince != null" class="list-group-item">
      <b>In Österreich seit: </b
      >{{ personalInfoRequest.inAtSince | date : "dd.MM.yyyy" }}
    </li>
    <li class="list-group-item">
      <b>Familienstand: </b>
      {{ personalInfoRequest.martialState | maritalState }}
    </li>
    <li class="list-group-item">
      <b>Ausbildung: </b> {{ personalInfoRequest.education | education }}
    </li>
    <li class="list-group-item">
      <b>Prägezeile 1: </b> {{ personalInfoRequest.embossingLine1 }}
    </li>
    <li
      *ngIf="personalInfoRequest.embossingLine2 != null"
      class="list-group-item"
    >
      <b>Prägezeile 2: </b> {{ personalInfoRequest.embossingLine2 }}
    </li>
    <li class="list-group-item">
      <a
        class="link-offset-2 link-underline link-underline-opacity-0"
        [routerLink]="['/personal-info']"
        routerLinkActive="router-link-active"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>
  <h2 class="mt-5">Kontaktdaten</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>Wohnsitzland: </b> {{ contactInfoRequest.livingCountry | country }}
    </li>
    <li
      *ngIf="
        contactInfoRequest.street != null &&
        contactInfoRequest.houseNumber != null
      "
      class="list-group-item"
    >
      <b>Adresse </b> {{ contactInfoRequest.street }}
      {{ contactInfoRequest.houseNumber }}
    </li>
    <li *ngIf="contactInfoRequest.line1 != null" class="list-group-item">
      <b>Adresszeile 1: </b> {{ contactInfoRequest.line1 }}
    </li>
    <li class="list-group-item" *ngIf="contactInfoRequest.line2 != null">
      <b>Adresszeile 2: </b> {{ contactInfoRequest.line2 }}
    </li>
    <li class="list-group-item">
      <b>Ort: </b> {{ contactInfoRequest.zip }}
      {{ contactInfoRequest.place }}
    </li>

    <li class="list-group-item">
      <b>Email: </b>{{ contactInfoRequest.email }}
    </li>

    <li class="list-group-item">
      <b>Mobilnummer: </b> {{ contactInfoRequest.phoneNumber }}
    </li>

    <li *ngIf="contactInfoRequest.sendToCorrespondence" class="list-group-item">
      <b>An Korrespondenzadresse senden: </b> Ja
    </li>
    <li
      *ngIf="
        contactInfoRequest.sendToCorrespondence &&
        contactInfoRequest.corLine1 != null
      "
      class="list-group-item"
    >
      <b>Adresszeile 1: </b>
      {{ contactInfoRequest.corLine1 }}
    </li>
    <li
      *ngIf="
        contactInfoRequest.sendToCorrespondence &&
        contactInfoRequest.line2 != null
      "
      class="list-group-item"
    >
      <b>Adresszeile 2: </b>
      {{ contactInfoRequest.corLine2 }}
    </li>
    <li
      *ngIf="
        contactInfoRequest.sendToCorrespondence &&
        contactInfoRequest.corStreet != null &&
        contactInfoRequest.corHouseNumber != null
      "
      class="list-group-item"
    >
      <b>Adresse </b> {{ contactInfoRequest.corStreet }}
      {{ contactInfoRequest.corHouseNumber }}
    </li>
    <li *ngIf="contactInfoRequest.sendToCorrespondence" class="list-group-item">
      <b>Ort: </b>
      {{ contactInfoRequest.corZip }}
      {{ contactInfoRequest.corPlace }}
    </li>
    <li *ngIf="contactInfoRequest.sendToCorrespondence" class="list-group-item">
      <b>Land: </b>
      {{ contactInfoRequest.corCountry | country }}
    </li>
    <li class="list-group-item">
      <a
        class="link-offset-2 link-underline link-underline-opacity-0"
        [routerLink]="['/contact-info']"
        routerLinkActive="router-link-active"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Legitimation</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>Ausweisart: </b> {{ legitimationInfoRequest.idType | idType }}
    </li>
    <li class="list-group-item">
      <b>Ausweisnummer: </b> {{ legitimationInfoRequest.idNumber }}
    </li>
    <li class="list-group-item">
      <b>Ausstellende Behörde: </b>
      {{ legitimationInfoRequest.issuingAuthority }}
    </li>
    <li
      class="list-group-item"
      *ngIf="legitimationInfoRequest.expirationDate != null"
    >
      <b>Ablaufdatum: </b>
      {{ legitimationInfoRequest.expirationDate | date : "dd.MM.yyyy" }}
    </li>
    <li class="list-group-item" *ngIf="legitimationInfoRequest.issuingDate">
      <b>Ausstellungsdatum: </b>
      {{ legitimationInfoRequest.issuingDate | date : "dd.MM.yyyy" }}
    </li>
    <li class="list-group-item" *ngIf="legitimationInfoRequest.issuingCountry">
      <b>Ausstellungsland: </b>
      {{ legitimationInfoRequest.issuingCountry | country }}
    </li>
    <li class="list-group-item">
      <a
        class="link-offset-2 link-underline link-underline-opacity-0"
        [routerLink]="['/legitimation-info']"
        routerLinkActive="router-link-active"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Bankverbindung</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>IBAN: </b>
      {{ bankInfoRequest.iban }}
    </li>
    <li class="list-group-item">
      <b>BLZ: </b>
      {{ bankInfoRequest.blz }}
    </li>
    <li class="list-group-item">
      <b>Bankname: </b>
      {{ bankInfoRequest.bankName }}
    </li>
    <li class="list-group-item">
      <b>Girokonto seit: </b>
      {{ bankInfoRequest.accountSince | date : "dd.MM.yyyy" }}
    </li>
    <li *ngIf="bankInfoRequest.accountOk" class="list-group-item">
      <b
        >Girokonto seit Kontoeröffnung bzw. seit mehr als 1 Jahr einwandfrei:</b
      >
      Ja
    </li>
    <li class="list-group-item">
      <a
        [routerLink]="['/bank-info']"
        routerLinkActive="router-link-active"
        class="link-offset-2 link-underline link-underline-opacity-0"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Finanzauskunft</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>Beruf: </b>
      {{ financialInfoRequest.occupation | occupation }}
    </li>
    <li *ngIf="financialInfoRequest.workingStudent" class="list-group-item">
      <b>Arbeitender Student:</b> Ja
    </li>
    <li *ngIf="financialInfoRequest.industry != null" class="list-group-item">
      <b>Branche: </b>
      {{ financialInfoRequest.industry | industry }}
    </li>
    <li *ngIf="financialInfoRequest.employer != null" class="list-group-item">
      <b>Arbeitgeber: </b>
      {{ financialInfoRequest.employer }}
    </li>
    <li
      *ngIf="financialInfoRequest.employedSince != null"
      class="list-group-item"
    >
      <b *ngIf="financialInfoRequest.occupation != Occupation.PENSIONER"
        >Beschäftigt seit:
      </b>
      <b *ngIf="financialInfoRequest.occupation == Occupation.PENSIONER"
        >In Pension seit:
      </b>
      {{ financialInfoRequest.employedSince | date : "dd.MM.yyyy" }}
    </li>
    <li
      *ngIf="financialInfoRequest.employerCountry != null"
      class="list-group-item"
    >
      <b *ngIf="financialInfoRequest.occupation != Occupation.PENSIONER"
        >Arbeitgeber Land:
      </b>
      <b *ngIf="financialInfoRequest.occupation == Occupation.PENSIONER"
        >Land:
      </b>
      {{ financialInfoRequest.employerCountry | country }}
    </li>
    <li class="list-group-item">
      <b *ngIf="financialInfoRequest.occupation != Occupation.PENSIONER"
        >Nettoeinkommen:
      </b>
      <b *ngIf="financialInfoRequest.occupation == Occupation.PENSIONER"
        >Netto Pension:
      </b>
      {{
        financialInfoRequest.income
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry == 'AT' &&
        financialInfoRequest.atAddress?.line1 != null
      "
    >
      <b>Adresszeile 1: </b>
      {{ financialInfoRequest.atAddress?.line1 }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry == 'AT' &&
        financialInfoRequest.atAddress?.line2 != null
      "
    >
      <b>Adresszeile 2: </b>
      {{ financialInfoRequest.atAddress?.line2 }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry == 'AT' &&
        financialInfoRequest.atAddress?.zip != null &&
        financialInfoRequest.atAddress?.place != null
      "
    >
      <b>Ort: </b>
      {{ financialInfoRequest.atAddress?.zip }}
      {{ financialInfoRequest.atAddress?.place }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry != 'AT' &&
        financialInfoRequest.foreignAddress?.line1 != null
      "
    >
      <b>Adresszeile 1: </b>
      {{ financialInfoRequest.foreignAddress?.line1 }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry != 'AT' &&
        financialInfoRequest.foreignAddress?.line2 != null
      "
    >
      <b>Adresszeile 2: </b>
      {{ financialInfoRequest.foreignAddress?.line2 }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        financialInfoRequest.employerCountry != 'AT' &&
        financialInfoRequest.foreignAddress?.line3 != null
      "
    >
      <b>Adresszeile 3: </b>
      {{ financialInfoRequest.foreignAddress?.line3 }}
    </li>
    <li *ngIf="financialInfoRequest.workingIncome" class="list-group-item">
      <b
        >Einkünfte aus unselbstständiger Erwerbstätigkeit / Pensionszahlungen:
      </b>
      Ja
    </li>
    <li *ngIf="financialInfoRequest.selfEmployedIncome" class="list-group-item">
      <b>Einkünfte aus selbstständiger Erwerbstätigkeit: </b> Ja
    </li>
    <li
      *ngIf="
        financialInfoRequest.stateIncome &&
        financialInfoRequest.stateIncomeText != null
      "
      class="list-group-item"
    >
      <b
        >Einkünfte aus staatlichen Beihilfen ({{
          financialInfoRequest.stateIncomeText
        }}):
      </b>
      Ja
    </li>
    <li *ngIf="financialInfoRequest.ongoingIncome" class="list-group-item">
      <b>Laufendes Einkommen aus Erträgnissen / Dividenden / Mieteinnahmen: </b>
      Ja
    </li>
    <li *ngIf="financialInfoRequest.apprenticeIncome" class="list-group-item">
      <b>Einkünfte aus Lehrlingsentschädigung: </b> Ja
    </li>
    <li *ngIf="financialInfoRequest.spendingIncome" class="list-group-item">
      <b>Taschengeld: </b> Ja
    </li>
    <li *ngIf="financialInfoRequest.inheritIncome" class="list-group-item">
      <b>Erbschaft / Schenkung: </b> Ja
    </li>
    <li *ngIf="financialInfoRequest.saleIncome" class="list-group-item">
      <b>Verkauf von Vermögenswerten: </b> Ja
    </li>
    <li *ngIf="financialInfoRequest.insuranceIncome" class="list-group-item">
      <b>Ablauf von Versicherungen / Verträgen: </b> Ja
    </li>
    <li
      *ngIf="
        financialInfoRequest.otherIncome &&
        financialInfoRequest.otherIncomeText != null
      "
      class="list-group-item"
    >
      <b>Sonstiges Einkommen ({{ financialInfoRequest.otherIncomeText }}): </b>
      Ja
    </li>
    <li class="list-group-item">
      <a
        [routerLink]="['/financial-info']"
        routerLinkActive="router-link-active"
        class="link-offset-2 link-underline link-underline-opacity-0"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Ausgewähltes Produkt</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <b>Produkt: </b>
      {{ selectedProduct.productName }}
      {{ selectedProduct.brand }}
      Hauptkarte
      {{ selectedProduct.condition | condition }}
    </li>
    <li class="list-group-item">
      <b>Verfügungsrahmen: </b>
      <span *ngIf="!selectedProduct.freeDrawLimit">{{
        selectedProduct.selectedDrawLimit
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}</span>
      <span *ngIf="selectedProduct.freeDrawLimit">{{
        selectedProduct.freeDrawLimitAmount
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}</span>
    </li>
    <li class="list-group-item">
      <b>Kondition: </b>
      {{ feeConditionDescription }}
    </li>
    <li class="list-group-item">
      <b>Zahlungsmethode: </b>
      {{ paymentInfoRequest.paybackMethod | paybackMethod }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        paymentInfoRequest.paybackPlan != null &&
        paymentInfoRequest.paybackMethod == 'PARTIAL_PAYMENT'
      "
    >
      <b>Rückzahlungsplan: </b>
      {{ paymentInfoRequest.paybackPlan | paybackPlan }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        paymentInfoRequest.paybackAmount != null &&
        paymentInfoRequest.paybackMethod == 'PARTIAL_PAYMENT' &&
        paymentInfoRequest.paybackPlan == 'AMOUNT'
      "
    >
      <b>Betrag: </b>
      {{
        paymentInfoRequest.paybackAmount
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        paymentInfoRequest.paybackPercentage != null &&
        paymentInfoRequest.paybackMethod == 'PARTIAL_PAYMENT' &&
        paymentInfoRequest.paybackPlan == 'PERCENTAGE'
      "
    >
      <b>Prozentsatz: </b>
      {{ paymentInfoRequest.paybackPercentage }}%
    </li>
    <li
      class="list-group-item"
      *ngIf="
        paymentInfoRequest.monthlyCreditLimit != null &&
        paymentInfoRequest.paybackMethod == 'PARTIAL_PAYMENT'
      "
    >
      <b>Kreditraten pro Monat: </b>
      {{
        paymentInfoRequest.monthlyCreditLimit
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}
    </li>
    <li
      class="list-group-item"
      *ngIf="
        paymentInfoRequest.monthlyLeasingRate != null &&
        paymentInfoRequest.paybackMethod == 'PARTIAL_PAYMENT'
      "
    >
      <b>Leasingraten pro Monat: </b>
      {{
        paymentInfoRequest.monthlyLeasingRate
          | currency : "EUR" : "symbol" : ".2-2" : "de-AT"
      }}
    </li>
    <li class="list-group-item">
      <a
        [routerLink]="['/product-selection']"
        routerLinkActive="router-link-active"
        class="link-offset-2 link-underline link-underline-opacity-0"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Rechtliches</h2>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" *ngIf="basicInfoRequest.ownDemand">
      <b>Auf eigene Rechnung: </b>
      Ja
    </li>
    <li class="list-group-item">
      <b>PEP: </b>
      {{ basicInfoRequest.pep | pep }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.activity != null">
      <b>Tätigkeit: </b>
      {{ basicInfoRequest.activity }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.function != null">
      <b>Funktion: </b>
      {{ basicInfoRequest.function | politicalFunction }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.relationship != null">
      <b>Beziehung: </b>
      {{ basicInfoRequest.relationship }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.firstName != null">
      <b>Vorname: </b>
      {{ basicInfoRequest.firstName }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.lastName != null">
      <b>Nachname: </b>
      {{ basicInfoRequest.lastName }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.birthday != null">
      <b>Geburtsdatum: </b>
      {{ basicInfoRequest.birthday | date : "dd.MM.yyyy" }}
    </li>
    <li class="list-group-item" *ngIf="basicInfoRequest.nationality != null">
      <b>Staatsangehörigkeit: </b>
      {{ basicInfoRequest.nationality | country }}
    </li>
    <li class="list-group-item">
      <a
        [routerLink]="['/basic-info']"
        routerLinkActive="router-link-active"
        class="link-offset-2 link-underline link-underline-opacity-0"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <h2 class="mt-5">Services</h2>
  <ul class="list-group list-group-flush mb-5">
    <li class="list-group-item" *ngIf="serviceInfoRequest.portal">
      <b>Serviceportal: </b>
      Ja
    </li>
    <li class="list-group-item" *ngIf="serviceInfoRequest.agbElectronic">
      <b>AGB elektronisch: </b>
      Ja
    </li>
    <li class="list-group-item" *ngIf="serviceInfoRequest.newsletter">
      <b>Newsletter: </b>
      Ja
    </li>
    <!--     <li class="list-group-item" *ngIf="serviceInfoRequest.paperPin">
      <b>Papier PIN: </b>
      Ja
    </li> -->
    <li class="list-group-item" *ngIf="serviceInfoRequest.paperBill">
      <b>Papier Abrechnung: </b>
      Ja
    </li>
    <li class="list-group-item" *ngIf="serviceInfoRequest.trxNotification">
      <b>Info SMS: </b>
      Ja
    </li>
    <li class="list-group-item">
      <a
        [routerLink]="['/service-info']"
        routerLinkActive="router-link-active"
        class="link-offset-2 link-underline link-underline-opacity-0"
        ><b>Angaben ändern</b></a
      >
    </li>
  </ul>

  <form [formGroup]="summaryForm" (ngSubmit)="onConfirm()">
    <div class="mb-3 form-check">
      <label for="confirmControl" class="form-check-label"
        >Der Berater bestätigt die persönliche Identifikation des Kunden gem.
        Finanzmarkt-Geldwäsche Gesetz (FM-GwG) und akzeptiert die geltende
        <a
          target="_blank"
          class="link-offset-2 link-underline link-underline-opacity-0"
          href="../../../../assets/files/Vergaberichtlinien-Privat-u-Business-02_2024.pdf"
          ><b>Vergaberichtlinie</b></a
        >
        für die Vergabe von PayLife Kreditkarten und bestätigt hiermit, dass
        alle Eingaben und Angaben korrekt sind und von ihm entsprechend geprüft
        wurden sowie die für die Vergabe der Kreditkarte notwendigen Dokumente
        hoch geladen wurden.</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="confirmControl"
        formControlName="confirm"
      />
    </div>
    <button
      type="submit"
      [disabled]="summaryForm.invalid"
      class="btn btn-primary"
    >
      Bestätigen
    </button>
  </form>
</div>
