<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <div *ngIf="!productsAvailable" class="alert alert-primary" role="alert">
    Leider können wir kein Produkt anbieten
  </div>
  <div
    *ngIf="unAllowedProduct || unAllowedLimit || showFreeLimit"
    class="alert alert-warning"
    role="alert"
  >
    Garantieerklärung notwendig!
  </div>

  <div *ngIf="productsAvailable">
    <form [formGroup]="selectProductForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label for="selectedProductControl" class="form-label"
          >Produkt: *</label
        >
        <ng-select
          id="selectedProductControl"
          placeholder="Bitte das Produkt auswählen"
          [items]="productList"
          bindValue="cup"
          formControlName="selectedProduct"
        >
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <!-- Apply the pipe to the item.name -->
            {{ item | product }}
          </ng-template>
        </ng-select>
        <div
          *ngIf="
            selectProductForm.controls['selectedProduct'].errors &&
            selectProductForm.controls['selectedProduct'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte das Produkt auswählen
        </div>
      </div>

      <div class="mb-3">
        <label for="selectedFeeConditionControl" class="form-label"
          >Kondition: *</label
        >
        <ng-select
          id="selectedFeeConditionControl"
          placeholder="Bitte die Kondition auswählen"
          [items]="feeConditionList"
          bindValue="id"
          bindLabel="description"
          formControlName="selectedFeeCondition"
        >
        </ng-select>
        <div
          *ngIf="
            selectProductForm.controls['selectedFeeCondition'].errors &&
            selectProductForm.controls['selectedFeeCondition'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte die Kondition auswählen
        </div>
      </div>

      <div *ngIf="showLimitType">
        <div class="mb-2"><b>Rahmenart: *</b></div>

        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            formControlName="freeDrawLimit"
            id="limitTypeSelect"
            [value]="false"
          />
          <label class="form-check-label" for="limitTypeSelect">
            Rahmen auswählen
          </label>
        </div>
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            formControlName="freeDrawLimit"
            id="limitTypeFree"
            [value]="true"
          />
          <label class="form-check-label" for="limitTypeFree">
            Freier Rahmen
          </label>
        </div>
      </div>

      <div *ngIf="showSelectedDrawLimit" class="mb-3">
        <!-- Verfügungsrahmen:
        {{ drawLimitValue | currency : "EUR" : "symbol" : ".2-2" : "de-AT" }} -->
        <label for="selectedDrawLimitControl" class="form-label"
          >Verfügungsrahmen: *</label
        >
        <ng-select
          id="selectedDrawLimitControl"
          placeholder="Bitte den Verfügungsrahmen auswählen"
          [items]="limitList"
          formControlName="selectedDrawLimit"
        >
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <!-- Apply the pipe to the item.name -->
            {{ item | currency : "EUR" : "symbol" : ".2-2" : "de-AT" }}
          </ng-template>
        </ng-select>
        <div
          *ngIf="
            selectProductForm.controls['selectedDrawLimit'].errors &&
            selectProductForm.controls['selectedDrawLimit'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte den Verfügungsrahmen auswählen
        </div>
      </div>

      <div class="mb-3" *ngIf="showFreeLimit">
        <label for="freeLimitControl" class="form-label"
          >Verfügungsrahmen: *</label
        >
        <input
          currencyMask
          onpaste="return false;"
          autocomplete="off"
          class="form-control"
          id="freeLimitControl"
          formControlName="freeDrawLimitAmount"
          placeholder="Bitte den Verügungsrahmen eingeben"
          [options]="{
            suffix: ' €',
            prefix: '',
            align: 'left',
            precision: 0,
            allowNegative: false,
            thousands: '.'
          }"
        />
        <div
          *ngIf="
            selectProductForm.controls['freeDrawLimitAmount'].errors &&
            selectProductForm.controls['freeDrawLimitAmount'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte einen passenden Rahmen eingeben:
          <ul>
            <li>Durch 500 teilbar</li>
            <li>Mindestens 10.500 €</li>
            <li>Maximal 500.000 € bei Black</li>
            <li>Maximal 1.000.000 € bei Platinum</li>
          </ul>
        </div>
      </div>

      <div class="mb-3">
        <div class="row form-label">
          <div class="col">
            <label for="paybackMethodControl"
              >Zahlungsmethode für die Monatsabrechnung: *</label
            >
          </div>
          <div class="col-auto text-end">
            <svg
              (click)="showMethodInfo = !showMethodInfo"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_15_702)">
                <path
                  d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                  fill="#000066"
                />
              </g>
              <defs>
                <clipPath id="clip0_15_702">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div *ngIf="showMethodInfo" class="alert alert-info" role="alert">
          Der Teilzahlungsfunktion liegt der Kreditvertrag „Teilzahlungsvertrag
          zur PayLife Kreditkarte“ zugrunde, den Sie zusammen mit Ihrem
          Kartenauftrag gemeinsam mit den vorvertraglichen Informationen
          („Europäische Standardinformationen für Kreditierungen nach dem
          Verbraucherkreditgesetz“) per E-Mail als PDF-Dokument erhalten.
          <br /><br />
          <div
            class="mb-3"
            *ngIf="
              selectProductForm.controls['selectedProduct'].value !== null &&
              selectProductForm.controls['selectedProduct'].value
                .productName === 'Classic'
            "
          >
            <b>Berechnungsbeispiel für die PayLife Classic:</b><br />
            Bei einem Gesamtkreditbetrag von EUR 2.200,– und einer Laufzeit von
            12 Monaten erhalten Kunden einen Sollzinssatz in der Höhe von 13,88
            % p. a. variabel, kein Bearbeitungsentgelt, Zinsen gesamt EUR 168,90
            Effektivzinssatz 14,80 % p. a., Gesamtrückzahlung EUR 2.368,90.
          </div>

          <div
            class="mb-3"
            *ngIf="
              selectProductForm.controls['selectedProduct'].value !== null &&
              selectProductForm.controls['selectedProduct'].value
                .productName === 'Black'
            "
          >
            <b>Rechenbeispiel für PayLife Black:</b><br />
            Bei einem Gesamtkreditbetrag von EUR 5.000,– und einer Laufzeit von
            12 Monaten erhalten Kunden einen Sollzinssatz in der Höhe von 13,88
            % p. a. variabel, kein Bearbeitungsentgelt, Zinsen gesamt EUR
            383,83, Effektivzinssatz 14,80 % p. a., Gesamtrückzahlung EUR
            5.383,83.
          </div>

          <div
            class="mb-3"
            *ngIf="
              selectProductForm.controls['selectedProduct'].value !== null &&
              selectProductForm.controls['selectedProduct'].value
                .productName === 'Platinum'
            "
          >
            <b>Berechnungsbeispiel für die PayLife Platinum Mastercard: </b
            ><br />
            Bei einem Gesamtkreditbetrag von EUR 10.000,– und einer Laufzeit von
            12 Monaten erhalten Kunden einen Sollzinssatz in der Höhe von 13,88
            % p. a. variabel, kein Bearbeitungsentgelt, Zinsen gesamt EUR 767,68
            Effektivzinssatz 14,80 % p. a., Gesamtrückzahlung EUR 10.767,68.
          </div>

          <div
            class="mb-3"
            *ngIf="
              selectProductForm.controls['selectedProduct'].value !== null &&
              (selectProductForm.controls['selectedProduct'].value
                .productName === 'Gold' ||
                selectProductForm.controls['selectedProduct'].value
                  .productName === 'Gold Plus')
            "
          >
            <b>Berechnungsbeispiel für die PayLife Gold/GoldPlus:</b><br />
            Bei einem Gesamtkreditbetrag von EUR 3.000,– und einer Laufzeit von
            12 Monaten erhalten Kunden einen Sollzinssatz in der Höhe von 13,88
            % p. a. variabel, kein Bearbeitungsentgelt, Zinsen gesamt EUR 230,30
            Effektivzinssatz 14,80 % p. a., Gesamtrückzahlung EUR 3.230,30.
          </div>
        </div>
        <ng-select
          id="paybackMethodControl"
          placeholder="Bitte die Zahlungsmethode auswählen"
          [items]="paybackMethods"
          bindLabel="label"
          bindValue="value"
          formControlName="paybackMethod"
        >
        </ng-select>
        <div
          *ngIf="
            selectProductForm.controls['paybackMethod'].errors &&
            selectProductForm.controls['paybackMethod'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte die Zahlungsmethode auswählen
        </div>
      </div>
      <div *ngIf="partialPayment">
        <div class="mb-3">
          <div class="row form-label">
            <div class="col">
              <label for="paybackPlanControl">Rückzahlungsplan: *</label>
            </div>
            <div class="col-auto text-end">
              <svg
                (click)="showPlanInfo = !showPlanInfo"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_15_702)">
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                    fill="#000066"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15_702">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <div *ngIf="showPlanInfo" class="alert alert-info" role="alert">
            <b>Monatlicher Mindestbetrag:</b> Wir rechnen den von Ihnen
            festgelegten Betrag monatlich von Ihrem Konto ab. Die monatliche
            Rate beträgt mindestens EUR 60,–. Restbeträge unter EUR 60,– werden
            auf einmal abgebucht. <br />
            <b>Monatlicher Mindestprozentsatz:</b> Wir rechnen den von Ihnen
            festgelegten Prozentsatz monatlich von Ihrem Konto ab. Die Höhe der
            Rate beträgt mindestens 10% oder mehr, ergibt der gewählte
            Prozentsatz einen geringeren Betrag als EUR 60,–, beträgt die
            Monatsrate dennoch EUR 60,– („Mindestbetrag“).
          </div>
          <ng-select
            id="paybackPlanControl"
            placeholder="Bitte den Rückzahlungsplan auswählen"
            [items]="paybackPlans"
            bindLabel="label"
            bindValue="value"
            formControlName="paybackPlan"
          >
          </ng-select>
          <div
            *ngIf="
              selectProductForm.controls['paybackPlan'].errors &&
              selectProductForm.controls['paybackPlan'].touched
            "
            class="alert alert-danger"
            role="alert"
          >
            Bitte den Rückzahlungsplan auswählen
          </div>
        </div>
        <div *ngIf="showAmount">
          <div class="mb-3">
            <label for="paybackAmountControl" class="form-label"
              >Betrag: *</label
            >
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              id="paybackAmountControl"
              formControlName="paybackAmount"
              placeholder="Bitte den Betrag eingeben"
              [options]="{
                suffix: ' €',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: '.'
              }"
            />
            <div
              *ngIf="
                selectProductForm.controls['paybackAmount'].errors &&
                selectProductForm.controls['paybackAmount'].touched
              "
              class="alert alert-danger"
              role="alert"
            >
              Bitte einen korrekten Betrag eingeben
            </div>
          </div>
        </div>
        <div *ngIf="showPercentage">
          <div class="mb-3">
            <label for="paybackPercentageControl" class="form-label"
              >Prozentsatz: *</label
            >
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              id="paybackPercentageControl"
              formControlName="paybackPercentage"
              placeholder="Bitte den Prozentsatz eingeben"
              [options]="{
                suffix: ' %',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: ''
              }"
            />
            <div
              *ngIf="
                selectProductForm.controls['paybackPercentage'].errors &&
                selectProductForm.controls['paybackPercentage'].touched
              "
              class="alert alert-danger"
              role="alert"
            >
              Bitte einen korrekten Prozentsatz eingeben
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="monthlyCreditLimitControl" class="form-label"
            >Monatliche Kreditraten:</label
          >
          <input
            currencyMask
            autocomplete="off"
            class="form-control"
            id="monthlyCreditLimitControl"
            formControlName="monthlyCreditLimit"
            placeholder="Bitte die monatlichen Kreditraten eingeben"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 2,
              allowNegative: false,
              thousands: '.',
              decimal: ','
            }"
          />
          <div
            *ngIf="
              selectProductForm.controls['monthlyCreditLimit'].errors &&
              selectProductForm.controls['monthlyCreditLimit'].touched
            "
            class="alert alert-danger"
            role="alert"
          >
            Bitte einen korrekten Betrag eingeben
          </div>
        </div>
        <div class="mb-3">
          <label for="monthlyLeasingRateControl" class="form-label"
            >Monatliche Leasingraten:</label
          >
          <input
            currencyMask
            autocomplete="off"
            class="form-control"
            id="monthlyLeasingRateControl"
            formControlName="monthlyLeasingRate"
            placeholder="Bitte die monatlichen Leasingraten eingeben"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 2,
              allowNegative: false,
              thousands: '.',
              decimal: ','
            }"
          />
          <div
            *ngIf="
              selectProductForm.controls['monthlyLeasingRate'].errors &&
              selectProductForm.controls['monthlyLeasingRate'].touched
            "
            class="alert alert-danger"
            role="alert"
          >
            Bitte einen korrekten Betrag eingeben
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row p-0">
          <div class="col text-start p-0">
            <button type="button" (click)="back()" class="btn btn-primary">
              Zurück
            </button>
          </div>
          <div class="col text-end p-0">
            <button
              type="submit"
              [disabled]="selectProductForm.invalid"
              class="btn btn-primary"
            >
              {{ submitText }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
