import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { ProductSelectionRequest } from 'src/app/dtos/ProductSelectionRequest';
import { ServiceInfoRequest } from 'src/app/dtos/ServiceInfoRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { ServiceInfoState } from 'src/app/states/ServiceInfoState';
import messages from '../../../assets/messages.json';
import { ProductName } from 'src/app/dtos/ProductName';
import { Additional } from 'src/app/dtos/Additional';

@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss'],
})
export class ServiceInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  //showPortalInfo: boolean = false;
  //showSMSInfo: boolean = false;

  @Emitter(ServiceInfoState.updateServiceInfo)
  updateServiceInfo$!: Emittable<ServiceInfoRequest>;
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};
  @Select(ServiceInfoState.getServiceInfo)
  getServiceInfo$!: Observable<ServiceInfoRequest>;
  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;
  selectedProduct: ProductResponse = {};
  acceptDefault: boolean = false;
  backendError: boolean = false;
  serviceInfoForm: FormGroup = {} as FormGroup;
  patchApplicationRequest: PatchApplicationRequest = {};
  serviceInfoRequest: ServiceInfoRequest = {};
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  submitText: string = 'Weiter';
  defaultSmsValue: boolean = false;
  trxNotificationText: string = '';
  suffixTrxNotificationText: string = '';
  paperBillText: string = '';
  showTrxSuffix: boolean = false;
  partnerFlow: boolean = false;

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.SERVICE_INFO;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
      this.acceptDefault = true;
    }
    if (this.appState.serviceAccepted) {
      this.acceptDefault = true;
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.getSelectedProduct$.subscribe((value) => {
      if (value.additional == Additional.PARTNER) {
        this.partnerFlow = true;
      }

      if (value.productName === ProductName.BLACK) {
        this.paperBillText = messages.freePaperBillMessage;
        this.defaultSmsValue = true;
        this.trxNotificationText = messages.blackTrxNotificationMessage;
      } else if (value.productName === ProductName.PLATINUM) {
        this.paperBillText = messages.freePaperBillMessage;
        this.defaultSmsValue = true;
        this.trxNotificationText = messages.platinumTrxNotificationMessage;
      } else {
        this.paperBillText = messages.defaultPaperBillMessage;
        this.defaultSmsValue = false;
        this.trxNotificationText = messages.defaultTrxNotificationMessage;
      }
      this.suffixTrxNotificationText = messages.suffixTrxNotificationMessage;
    });

    this.serviceInfoForm = this.formBuilder.group({
      portal: [true, [Validators.requiredTrue]],
      newsletter: [true, [Validators.required]],
      trxNotification: [this.defaultSmsValue, [Validators.required]],
      paperBill: [false, [Validators.required]],
      //paperPin: [false, [Validators.required]],
      accept: [this.acceptDefault, [Validators.requiredTrue]],
      pin: [true, [Validators.requiredTrue]],
    });

    this.serviceInfoForm.controls['portal'].disable();
    this.serviceInfoForm.controls['pin'].disable();

    this.getServiceInfo$.subscribe((value) => {
      if (value.portal != undefined) {
        this.serviceInfoForm.controls['portal'].patchValue(value.portal);
      }
      if (value.newsletter != undefined) {
        this.serviceInfoForm.controls['newsletter'].patchValue(
          value.newsletter
        );
      }
      if (value.trxNotification != undefined) {
        this.serviceInfoForm.controls['trxNotification'].patchValue(
          value.trxNotification
        );
      }
      if (value.paperBill != undefined) {
        this.serviceInfoForm.controls['paperBill'].patchValue(value.paperBill);
      }
      /*       if (value.paperPin != undefined) {
        this.serviceInfoForm.controls['paperPin'].patchValue(value.paperPin);
      } */
    });
  }

  onSubmit(): void {
    this.serviceInfoRequest = {
      portal: this.serviceInfoForm.controls['portal'].value,
      newsletter: this.serviceInfoForm.controls['newsletter'].value,
      trxNotification: this.serviceInfoForm.controls['trxNotification'].value,
      paperBill: this.serviceInfoForm.controls['paperBill'].value,
      //paperPin: this.serviceInfoForm.controls['paperPin'].value,
    };

    this.patchApplicationRequest = {
      serviceInfo: this.serviceInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateServiceInfo$.emit(this.serviceInfoRequest);
          this.appState.serviceAccepted =
            this.serviceInfoForm.controls['accept'].value;
          this.updateAppState$.emit(this.appState);
          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('file-upload');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  back(): void {
    this.router.navigateByUrl('bank-info');
  }
}
