import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { FeeCondition } from 'src/app/dtos/FeeCondition';
import { FinancialInfoRequest } from 'src/app/dtos/FinancialInfoRequest';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { PaybackMethod, PaybackMethods } from 'src/app/dtos/PaybackMethod';
import { PaybackPlan, PaybackPlans } from 'src/app/dtos/PaybackPlan';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { PersonalInfoRequest } from 'src/app/dtos/PersonalInfoRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { ProductSelectionRequest } from 'src/app/dtos/ProductSelectionRequest';
import { ConditionPipe } from 'src/app/pipes/condition.pipe';
import { ProductPipe } from 'src/app/pipes/product.pipe';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { FinancialInfoState } from 'src/app/states/FinancialInfoState';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { divisableBy } from 'src/app/validators/CustomValidator';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router,
    private conditionPipe: ConditionPipe,
    private productPipe: ProductPipe
  ) {}

  showMethodInfo: boolean = false;
  showPlanInfo: boolean = false;
  unAllowedProduct: boolean = false;
  unAllowedLimit: boolean = false;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};
  @Select(PaymentInfoState.getPaymentInfo)
  getPaymentInfo$!: Observable<PaymentInfoRequest>;
  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;
  @Emitter(SelectedProductState.updateSelectedProduct)
  updateSelectedProduct$!: Emittable<ProductResponse>;
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  @Emitter(PaymentInfoState.updatePaymentInfo)
  updatePaymentInfo$!: Emittable<PaymentInfoRequest>;
  @Select(FinancialInfoState.getFinancialInfo)
  getFinancialInfo$!: Observable<FinancialInfoRequest>;
  financialInfo: FinancialInfoRequest = {};
  @Select(PersonalInfoState.getPersonalInfo)
  getPersonalInfo$!: Observable<PersonalInfoRequest>;
  personalInfo: PersonalInfoRequest = {};

  backendError: boolean = false;
  productsAvailable: boolean = true;
  allowedProductList: any[] = [];
  productList: any[] = [];
  limitList: any[] = [];
  allowedLimitList: any[] = [];
  feeConditionList: any[] = [];
  selectProductForm: FormGroup = {} as FormGroup;
  paybackMethods = PaybackMethods;
  paybackPlans = PaybackPlans;
  partialPayment: boolean = false;
  showAmount: boolean = false;
  showPercentage: boolean = false;
  patchApplicationRequest: PatchApplicationRequest = {};
  productSelectionRequest: ProductSelectionRequest = {};
  paymentInfoRequest: PaymentInfoRequest = {};
  //showDrawLimit: boolean = false;
  //drawLimitValue: number = 0;
  submitText: string = 'Weiter';
  showLimitType: boolean = false;
  showFreeLimit: boolean = false;
  showSelectedDrawLimit: boolean = false;
  selectedProductName: string = '';

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.PRODUCT_SELECTION;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getPersonalInfo$.subscribe((value) => (this.personalInfo = value));
    this.getFinancialInfo$.subscribe((value) => (this.financialInfo = value));

    this.selectProductForm = this.formBuilder.group({
      selectedProduct: [null, [Validators.required]],
      selectedDrawLimit: [null, []],
      selectedFeeCondition: [null, [Validators.required]],
      paybackMethod: [PaybackMethod.MONTHLY_PAYMENT, [Validators.required]],
      paybackPlan: [null, []],
      paybackAmount: [null, []],
      paybackPercentage: [null, []],
      monthlyCreditLimit: [null, []],
      monthlyLeasingRate: [null, []],
      freeDrawLimit: [false, []],
      freeDrawLimitAmount: [null, []],
    });

    this.selectProductForm.controls['selectedProduct'].valueChanges.subscribe(
      (value) => {
        this.unAllowedProduct = false;
        this.showLimitType = false;
        this.showSelectedDrawLimit = false;
        this.selectedProductName = '';

        this.selectProductForm.controls['selectedDrawLimit'].patchValue(null);
        this.selectProductForm.controls['selectedDrawLimit'].clearValidators();
        this.selectProductForm.controls['freeDrawLimit'].patchValue(false);
        this.selectProductForm.controls['freeDrawLimit'].clearValidators();

        this.selectProductForm.controls['selectedFeeCondition'].patchValue(
          null
        );

        if (value !== null) {
          let product: ProductResponse = this.getProductByCup(value);
          this.selectedProductName = product.productName!;

          if (
            product.productName === 'Black' ||
            product.productName === 'Platinum'
          ) {
            this.showLimitType = true;
            this.selectProductForm.controls['freeDrawLimit'].setValidators([
              Validators.required,
            ]);
          } else {
            this.showSelectedDrawLimit = true;
            this.selectProductForm.controls['selectedDrawLimit'].setValidators([
              Validators.required,
            ]);
          }

          this.limitList = this.getDrawLimitsFromCup(value);
          this.allowedLimitList = this.getAllowedDrawLimitsFromCup(value);
          this.feeConditionList = this.getFeeConditionsFromCup(value);
          //this.drawLimitValue = this.getDrawLimitFromCup(value);

          if (!this.isCupAllowed(value)) {
            this.unAllowedProduct = true;
          }
        } else {
          this.limitList = [];
          this.feeConditionList = [];
          this.allowedLimitList = [];
        }

        this.selectProductForm.controls[
          'freeDrawLimit'
        ].updateValueAndValidity();
        this.selectProductForm.controls[
          'selectedDrawLimit'
        ].updateValueAndValidity();
      }
    );

    this.selectProductForm.controls['freeDrawLimit'].valueChanges.subscribe(
      (value) => {
        this.showFreeLimit = false;
        this.selectProductForm.controls['freeDrawLimitAmount'].patchValue(null);
        this.selectProductForm.controls[
          'freeDrawLimitAmount'
        ].clearValidators();

        //selected limit
        if (value == false) {
          this.showSelectedDrawLimit = true;
          this.selectProductForm.controls['selectedDrawLimit'].setValidators([
            Validators.required,
          ]);
        }
        // free limit
        if (value == true) {
          this.showSelectedDrawLimit = false;
          this.selectProductForm.controls['selectedDrawLimit'].patchValue(null);
          this.selectProductForm.controls[
            'selectedDrawLimit'
          ].clearValidators();

          if (this.selectedProductName == 'Black') {
            this.showFreeLimit = true;
            this.selectProductForm.controls[
              'freeDrawLimitAmount'
            ].setValidators([
              Validators.required,
              Validators.max(500000),
              Validators.min(10500),
              divisableBy(500),
            ]);
          }

          if (this.selectedProductName == 'Platinum') {
            this.showFreeLimit = true;
            this.selectProductForm.controls[
              'freeDrawLimitAmount'
            ].setValidators([
              Validators.required,
              Validators.max(1000000),
              Validators.min(10500),
              divisableBy(500),
            ]);
          }
        }

        this.selectProductForm.controls[
          'selectedDrawLimit'
        ].updateValueAndValidity();
        this.selectProductForm.controls[
          'freeDrawLimitAmount'
        ].updateValueAndValidity();
      }
    );

    this.selectProductForm.controls['selectedDrawLimit'].valueChanges.subscribe(
      (value) => {
        this.unAllowedLimit = false;

        if (value !== null) {
          if (!this.allowedLimitList.includes(value)) {
            this.unAllowedLimit = true;
          }
        }
      }
    );

    this.selectProductForm.controls['paybackMethod'].valueChanges.subscribe(
      (value) => {
        const paybackPlan = this.selectProductForm.controls['paybackPlan'];
        const monthlyCreditLimit =
          this.selectProductForm.controls['monthlyCreditLimit'];
        const monthlyLeasingRate =
          this.selectProductForm.controls['monthlyLeasingRate'];

        if (value === PaybackMethod.PARTIAL_PAYMENT) {
          this.partialPayment = true;
          paybackPlan.setValidators([Validators.required]);
          paybackPlan.setValue(PaybackPlan.AMOUNT);
          monthlyCreditLimit.setValidators([
            Validators.min(0),
            Validators.max(99999),
          ]);
          monthlyLeasingRate.setValidators([
            Validators.min(0),
            Validators.max(99999),
          ]);
        } else {
          this.partialPayment = false;
          paybackPlan.clearValidators();
          paybackPlan.setValue(null);
          monthlyCreditLimit.clearValidators();
          monthlyCreditLimit.setValue(null);
          monthlyLeasingRate.clearValidators;
          monthlyLeasingRate.setValue(null);
        }

        paybackPlan.updateValueAndValidity();
        monthlyCreditLimit.updateValueAndValidity();
        monthlyLeasingRate.updateValueAndValidity();
      }
    );

    this.selectProductForm.controls['paybackPlan'].valueChanges.subscribe(
      (value) => {
        const paybackAmount = this.selectProductForm.controls['paybackAmount'];
        const paybackPercentage =
          this.selectProductForm.controls['paybackPercentage'];

        if (value === PaybackPlan.AMOUNT) {
          this.showAmount = true;
          this.showPercentage = false;
          paybackAmount.setValidators([
            Validators.required,
            Validators.min(60),
            Validators.max(10000),
          ]);
          paybackAmount.setValue(60);
          paybackPercentage.clearValidators();
          paybackPercentage.setValue(null);
        } else if (value == PaybackPlan.PERCENTAGE) {
          this.showAmount = false;
          this.showPercentage = true;
          paybackAmount.clearValidators();
          paybackAmount.setValue(null);
          paybackPercentage.setValidators([
            Validators.required,
            Validators.min(10),
            Validators.max(100),
          ]);
          paybackPercentage.setValue(10);
        } else {
          this.showAmount = false;
          this.showPercentage = false;
          paybackAmount.clearValidators();
          paybackAmount.setValue(null);
          paybackPercentage.clearValidators();
          paybackPercentage.setValue(null);
        }

        paybackAmount.updateValueAndValidity();
        paybackPercentage.updateValueAndValidity();
      }
    );

    this.getProductsFromApi();
  }

  onSubmit(): void {
    let cup: string = this.selectProductForm.controls['selectedProduct'].value;
    let selectedProduct: ProductResponse = this.getProductByCup(cup);

    let selectedDrawLimit =
      this.selectProductForm.controls['selectedDrawLimit'].value;
    selectedProduct.selectedDrawLimit = selectedDrawLimit;
    let selectedFeeConditionId: string =
      this.selectProductForm.controls['selectedFeeCondition'].value;
    selectedProduct.selectedFeeCondition = selectedFeeConditionId;
    let freeDrawLimit = this.selectProductForm.controls['freeDrawLimit'].value;
    selectedProduct.freeDrawLimit = freeDrawLimit;
    let freeDrawLimitAmount =
      this.selectProductForm.controls['freeDrawLimitAmount'].value;
    selectedProduct.freeDrawLimitAmount = freeDrawLimitAmount;

    this.paymentInfoRequest = {
      paybackMethod: this.selectProductForm.controls['paybackMethod'].value,
      paybackPlan: this.selectProductForm.controls['paybackPlan'].value,
      paybackAmount: this.selectProductForm.controls['paybackAmount'].value,
      paybackPercentage:
        this.selectProductForm.controls['paybackPercentage'].value,
      monthlyCreditLimit:
        this.selectProductForm.controls['monthlyCreditLimit'].value,
      monthlyLeasingRate:
        this.selectProductForm.controls['monthlyLeasingRate'].value,
    };

    if (this.selectProductForm.controls['selectedProduct'].dirty) {
      this.productSelectionRequest = {
        cup: cup,
        drawLimit: selectedDrawLimit,
        feeCondition: selectedFeeConditionId,
        freeDrawLimit: freeDrawLimit,
        freeDrawLimitAmount: freeDrawLimitAmount,
      };
      this.patchApplicationRequest = {
        paymentInfo: this.paymentInfoRequest,
        productSelection: this.productSelectionRequest,
      };
    } else {
      this.productSelectionRequest = {
        drawLimit: selectedDrawLimit,
        feeCondition: selectedFeeConditionId,
        freeDrawLimit: freeDrawLimit,
        freeDrawLimitAmount: freeDrawLimitAmount,
      };
      this.patchApplicationRequest = {
        paymentInfo: this.paymentInfoRequest,
        productSelection: this.productSelectionRequest,
      };
    }

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateSelectedProduct$.emit(selectedProduct);
          this.updatePaymentInfo$.emit(this.paymentInfoRequest);
          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('basic-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getDrawLimitsFromCup(cup: string): number[] {
    let helper: any[] = this.productList;
    let drawLimits: number[] = [];
    helper.forEach((i) => {
      if (i.cup == cup) {
        drawLimits = i.drawLimits;
        return;
      }
    });
    return drawLimits;
  }

  getAllowedDrawLimitsFromCup(cup: string): number[] {
    let helper: any[] = this.allowedProductList;
    let drawLimits: number[] = [];
    helper.forEach((i) => {
      if (i.cup == cup) {
        drawLimits = i.drawLimits;
        return;
      }
    });
    return drawLimits;
  }

  getFeeConditionsFromCup(cup: string): FeeCondition[] {
    let helper: any[] = this.productList;
    let feeConditons: FeeCondition[] = [];
    helper.forEach((i) => {
      if (i.cup === cup) {
        feeConditons = i.feeConditions;
        return;
      }
    });
    return feeConditons;
  }

  getProductByCup(cup: string): ProductResponse {
    let helper: any[] = this.productList;
    let product: ProductResponse = {};
    helper.forEach((i) => {
      if (i.cup == cup) {
        product = i;
        return;
      }
    });
    return product;
  }

  isCupAllowed(cup: string): boolean {
    let helper: any[] = this.allowedProductList;
    let isAllowed: boolean = false;
    helper.forEach((i) => {
      if (i.cup == cup) {
        isAllowed = true;
        return;
      }
    });

    return isAllowed;
  }

  back(): void {
    this.router.navigateByUrl('financial-info');
  }

  getProductsFromApi(): void {
    const productsRequest = this.api.getActiveProducts(
      this.personalInfo.birthday!,
      this.financialInfo.occupation!,
      2147483647,
      this.financialInfo.industry!,
      this.financialInfo.workingIncome!
    );

    const allowedProductsRequest = this.api.getActiveProducts(
      this.personalInfo.birthday!,
      this.financialInfo.occupation!,
      this.financialInfo.income!,
      this.financialInfo.industry!,
      this.financialInfo.workingIncome!
    );

    forkJoin([productsRequest, allowedProductsRequest]).subscribe({
      next: ([productResponse, allowedProductResponse]) => {
        this.productList = productResponse;
        this.allowedProductList = allowedProductResponse;
        this.patchValuesFromStore();
      },
      error: (error) => {
        this.backendError = true;
        console.log(error);
      },
    });
  }

  patchValuesFromStore(): void {
    this.getSelectedProduct$.subscribe((value) => {
      if (value.cup != undefined) {
        this.selectProductForm.controls['selectedProduct'].patchValue(
          value.cup
        );
        this.selectProductForm.controls['selectedProduct'].disable();
      }

      if (value.feeConditions !== undefined) {
        this.feeConditionList = value.feeConditions;
      }

      if (value.selectedFeeCondition !== undefined) {
        this.selectProductForm.controls['selectedFeeCondition'].patchValue(
          value.selectedFeeCondition
        );
      }

      // if (value.drawLimits !== undefined) {
      //   this.limitList = value.drawLimits;
      // }

      if (value.freeDrawLimit !== undefined) {
        this.selectProductForm.controls['freeDrawLimit'].patchValue(
          value.freeDrawLimit
        );
      }

      if (value.freeDrawLimitAmount !== undefined) {
        this.selectProductForm.controls['freeDrawLimitAmount'].patchValue(
          value.freeDrawLimitAmount
        );
      }

      if (value.selectedDrawLimit != undefined) {
        this.selectProductForm.controls['selectedDrawLimit'].patchValue(
          value.selectedDrawLimit
        );
      }

      /*       if (value.drawLimit != undefined) {
        this.drawLimitValue = value.drawLimit;
      } */
    });

    this.getPaymentInfo$.subscribe((value) => {
      if (value.paybackMethod !== undefined) {
        this.selectProductForm.controls['paybackMethod'].patchValue(
          value.paybackMethod
        );
      }
      if (value.paybackPlan !== undefined) {
        this.selectProductForm.controls['paybackPlan'].patchValue(
          value.paybackPlan
        );
      }
      if (value.paybackAmount !== undefined) {
        this.selectProductForm.controls['paybackAmount'].patchValue(
          value.paybackAmount
        );
      }
      if (value.paybackPercentage !== undefined) {
        this.selectProductForm.controls['paybackPercentage'].patchValue(
          value.paybackPercentage
        );
      }
      if (value.monthlyCreditLimit !== undefined) {
        this.selectProductForm.controls['monthlyCreditLimit'].patchValue(
          value.monthlyCreditLimit
        );
      }
      if (value.monthlyLeasingRate !== undefined) {
        this.selectProductForm.controls['monthlyLeasingRate'].patchValue(
          value.monthlyLeasingRate
        );
      }
    });
  }
}
