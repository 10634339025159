import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { PersonalInfoRequest } from '../dtos/PersonalInfoRequest';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';

export const EmptyPersonalInfoRequest = {
  academicTitle: undefined,
  occupationTitle: undefined,
  salutation: undefined,
  firstName: undefined,
  lastName: undefined,
  birthday: undefined,
  citizenship: undefined,
  inAtSince: undefined,
  martialState: undefined,
  education: undefined,
  embossingLine1: undefined,
  embossingLine2: undefined,
  postposedAcademicTitle: undefined,
};

@State<PersonalInfoRequest>({
  name: 'personalInfo',
  defaults: EmptyPersonalInfoRequest,
})
@Injectable()
export class PersonalInfoState {
  @Selector()
  public static getPersonalInfo(
    state: PersonalInfoRequest
  ): PersonalInfoRequest {
    return state;
  }

  @Selector()
  public static getCitizenshipt(
    state: PersonalInfoRequest
  ): string | undefined {
    return state.citizenship;
  }

  @Receiver()
  public static updatePersonalInfo(
    { getState, patchState }: StateContext<PersonalInfoRequest>,
    { payload }: EmitterAction<PersonalInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
