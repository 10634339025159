import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { ProductResponse } from '../dtos/ProductResponse';

export const EmptySelectedProductRequest = {
  cup: undefined,
  brand: undefined,
  condition: undefined,
  productName: undefined,
  selectedDrawLimit: undefined,
  drawLimits: undefined,
  feeConditions: undefined,
  selectedFeeCondition: undefined,
  freeDrawLimit: undefined,
  freeDrawLimitAmount: undefined,
  additional: undefined,
};

@State<ProductResponse>({
  name: 'selectedProduct',
  defaults: EmptySelectedProductRequest,
})
@Injectable()
export class SelectedProductState {
  @Selector()
  public static getSelectedProduct(state: ProductResponse): ProductResponse {
    return state;
  }
  @Receiver()
  public static updateSelectedProduct(
    { getState, patchState }: StateContext<ProductResponse>,
    { payload }: EmitterAction<ProductResponse>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
