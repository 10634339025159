<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <div class="alert alert-info" role="alert">
    <b>Bitte beachten Sie bei Student/in folgende Punkte:</b><br />
    Wenn neben dem Studium bereits gearbeitet wird, ist trotzdem „Student/in“
    auszuwählen.<br />
    Wenn Sie bei Student/in angeben, dass neben dem Studium nicht gearbeitet
    wird, können Angaben bei Einkommen aus unselbstständiger sowie
    selbstständiger Erwerbstätigkeit nicht berücksichtigt werden!
  </div>
  <form [formGroup]="financialInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="occupationControl" class="form-label">Beruf: *</label>
      <ng-select
        id="occupationControl"
        placeholder="Bitte den Beruf auswählen"
        [items]="occupations"
        bindLabel="label"
        bindValue="value"
        formControlName="occupation"
      >
      </ng-select>
      <div
        *ngIf="
          financialInfoForm.controls['occupation'].errors &&
          financialInfoForm.controls['occupation'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen Beruf auswählen
      </div>
    </div>

    <div
      *ngIf="showWorkingStudent"
      class="border border-primary p-3 mb-3 rounded border-3"
    >
      <p>Arbeiten Sie neben Ihrem Studium? *</p>
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="radio"
          formControlName="workingStudent"
          id="workingControl"
          [value]="true"
        />
        <label class="form-check-label" for="workingControl"> Ja </label>
      </div>
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="radio"
          formControlName="workingStudent"
          id="notWorkingControl"
          [value]="false"
        />
        <label class="form-check-label" for="notWorkingControl"> Nein </label>
      </div>
    </div>

    <div *ngIf="showIndustry" class="mb-3">
      <div class="row form-label">
        <div class="col"><label for="industryControl">Branche: *</label></div>
        <div class="col-auto text-end">
          <img
            src="../../../assets/images/info.svg"
            (click)="showIndustryInfo = !showIndustryInfo"
          />
        </div>
      </div>

      <div *ngIf="showIndustryInfo" class="alert alert-info" role="alert">
        Bitte geben Sie die Branche des Arbeitgebers bekannt. Sollte keine
        passende Auswahl vorhanden sein, bitte "Sonstiges" auswählen und die
        Branche spezifizieren.
      </div>
      <ng-select
        id="industryControl"
        placeholder="Bitte die Branche auswählen"
        [items]="industries"
        bindLabel="label"
        bindValue="value"
        formControlName="industry"
      >
      </ng-select>
      <div
        *ngIf="
          financialInfoForm.controls['industry'].errors &&
          financialInfoForm.controls['industry'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte die Branche auswählen
      </div>
    </div>
    <div *ngIf="showEmployer" class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="employerControl">{{ employerLabel }}</label>
        </div>
        <div class="col-auto text-end">
          <img
            src="../../../assets/images/info.svg"
            (click)="showEmployerInfo = !showEmployerInfo"
          />
        </div>
      </div>

      <div *ngIf="showEmployerInfo" class="alert alert-info" role="alert">
        Wenn kein aufrechtes Beschäftigungsverhältnis vorliegt, geben Sie bitte
        die bezugsauszahlende Stelle bekannt.
      </div>
      <input
        type="text"
        class="form-control"
        id="employerControl"
        placeholder="{{ employerPlaceholder }}"
        formControlName="employer"
      />
      <div
        *ngIf="
          financialInfoForm.controls['employer'].errors &&
          financialInfoForm.controls['employer'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        {{ employerError }}
      </div>
    </div>
    <div *ngIf="showEmployedSince" class="mb-3">
      <label for="employedSinceControl" class="form-label">{{
        employedSinceLable
      }}</label>
      <input
        type="date"
        class="form-control"
        id="employedSinceControl"
        formControlName="employedSince"
        max="{{ today | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          financialInfoForm.controls['employedSince'].errors &&
          financialInfoForm.controls['employedSince'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum auswählen
      </div>
    </div>
    <div *ngIf="showEmployerCountry" class="mb-3">
      <label for="employerCountryControl" class="form-label"
        >Arbeitgeber Land: *</label
      >
      <ng-select
        id="employerCountryControl"
        placeholder="Bitte das Arbeitgeber Land auswählen"
        [items]="countries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="employerCountry"
      >
      </ng-select>
      <div
        *ngIf="
          financialInfoForm.controls['employerCountry'].errors &&
          financialInfoForm.controls['employerCountry'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein Arbeitgeber Land auswählen
      </div>
    </div>

    <div *ngIf="showPensionerCountry" class="mb-3">
      <label for="pensionerCountryControl" class="form-label">Land: *</label>
      <ng-select
        id="pensionerCountryControl"
        placeholder="Bitte das Land auswählen"
        [items]="pensionerCountries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="pensionerCountry"
      >
      </ng-select>
      <div
        *ngIf="
          financialInfoForm.controls['employerCountry'].errors &&
          financialInfoForm.controls['employerCountry'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein Land auswählen
      </div>
    </div>

    <div
      *ngIf="showAtAddress"
      class="border border-primary p-3 mb-3 rounded border-3"
    >
      <div class="mb-3">
        <div class="row form-label">
          <div class="col">
            <label for="atLine1Control">Adresszeile 1: *</label>
          </div>
          <div class="col-auto text-end">
            <div class="col-auto text-end">
              <img
                src="../../../assets/images/info.svg"
                (click)="showAtLineInfo = !showAtLineInfo"
              />
            </div>
          </div>
        </div>

        <div *ngIf="showAtLineInfo" class="alert alert-info" role="alert">
          Geben Sie bitte die Anschrift des Firmensitzes bekannt: Straße,
          Hausnummer, etc.
        </div>
        <input
          type="text"
          class="form-control"
          id="atLine1Control"
          placeholder="Bitte die erste Adresszeile eingeben"
          formControlName="atLine1"
        />
        <div
          *ngIf="
            financialInfoForm.controls['atLine1'].errors &&
            financialInfoForm.controls['atLine1'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>
      <div class="mb-3">
        <label for="atLine2Control" class="form-label">Adresszeile 2:</label>
        <input
          type="text"
          class="form-control"
          id="atLine2Control"
          placeholder="Bitte die zweite Adresszeile eingeben"
          formControlName="atLine2"
        />
        <div
          *ngIf="
            financialInfoForm.controls['atLine2'].errors &&
            financialInfoForm.controls['atLine2'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>
      <div class="mb-3">
        <label for="atZipControl" class="form-label">PLZ: *</label>
        <ng-select
          id="atZipControl"
          placeholder="Bitte eine PLZ auswählen"
          [items]="zips"
          bindLabel="plz"
          bindValue="plz"
          formControlName="atZip"
        >
        </ng-select>
        <div
          *ngIf="
            financialInfoForm.controls['atZip'].errors &&
            financialInfoForm.controls['atZip'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine PLZ auswählen
        </div>
      </div>
      <div class="mb-3">
        <label for="atPlaceControl" class="form-label">Ort: *</label>
        <input
          type="text"
          class="form-control"
          id="atPlaceControl"
          placeholder="Bitte den Ort eingeben"
          formControlName="atPlace"
          maxlength="25"
        />
        <div
          *ngIf="
            financialInfoForm.controls['atPlace'].errors &&
            financialInfoForm.controls['atPlace'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte einen Ort eingeben
        </div>
      </div>
    </div>

    <div
      *ngIf="showForeignAddress"
      class="border border-primary p-3 mb-3 rounded border-3"
    >
      <div class="mb-3">
        <div class="row form-label">
          <div class="col">
            <label for="forLine1Control">Adresszeile 1: *</label>
          </div>
          <div class="col-auto text-end">
            <img
              src="../../../assets/images/info.svg"
              (click)="showForeignLineInfo = !showForeignLineInfo"
            />
          </div>
        </div>

        <div *ngIf="showForeignLineInfo" class="alert alert-info" role="alert">
          Geben Sie bitte die Anschrift des Firmensitzes bekannt: Straße,
          Hausnummer, etc. Bei ausländischen Adressen geben Sie in Adresszeile 2
          die PLZ und den Ort ein.
        </div>
        <input
          type="text"
          class="form-control"
          id="forLine1Control"
          placeholder="Bitte die erste Adresszeile eingeben"
          formControlName="forLine1"
        />
        <div
          *ngIf="
            financialInfoForm.controls['forLine1'].errors &&
            financialInfoForm.controls['forLine1'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>

      <div class="mb-3">
        <label for="forLine2Control" class="form-label">Adresszeile 2: *</label>
        <input
          type="text"
          class="form-control"
          id="forLine2Control"
          placeholder="Bitte die zweite Adresszeile eingeben"
          formControlName="forLine2"
        />
        <div
          *ngIf="
            financialInfoForm.controls['forLine2'].errors &&
            financialInfoForm.controls['forLine2'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>

      <div class="mb-3">
        <label for="forLine3Control" class="form-label">Adresszeile 3:</label>
        <input
          type="text"
          class="form-control"
          id="forLine3Control"
          placeholder="Bitte die dritte Adresszeile eingeben"
          formControlName="forLine3"
        />
        <div
          *ngIf="
            financialInfoForm.controls['forLine3'].errors &&
            financialInfoForm.controls['forLine3'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte eine korrekte Adresszeile eingeben
        </div>
      </div>
    </div>
    <!--     <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="incomeControl">{{ incomeLablel }}</label>
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showIncomeInfo = !showIncomeInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div
        *ngIf="showIncomeInfo && !showWorkingStudent"
        class="alert alert-info"
        role="alert"
      >
        Durchschnittliches Monatsnettoeinkommen (inkl. regelmäßiger Zulagen und
        Überstunden, ohne AMS Bezüge oder sonstige Einkommen) der letzten drei
        Monate.
      </div>
      <div
        *ngIf="showIncomeInfo && showWorkingStudent"
        class="alert alert-info"
        role="alert"
      >
        Einkünfte aus Stipendium oder Waisenpension können als Einkommen
        angegeben werden.
      </div>
      <input
        currencyMask
        onpaste="return false;"
        autocomplete="off"
        class="form-control"
        id="incomeControl"
        formControlName="income"
        placeholder="{{ incomePlaceholder }}"
        [options]="{
          suffix: ' €',
          prefix: '',
          align: 'left',
          precision: 0,
          allowNegative: false,
          thousands: '.'
        }"
      />
      <div
        *ngIf="
          financialInfoForm.controls['income'].errors &&
          financialInfoForm.controls['income'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        {{ incomeError }}
      </div>
    </div> -->
    <div class="row mb-3 mt-5">
      <div class="col">
        <h6>Monatliches Einkommen / Herkunft der Einkommen: *</h6>
      </div>
      <div class="text-end col-auto">
        <img
          src="../../../assets//images/info.svg"
          (click)="showIncomeInfoBox = !showIncomeInfoBox"
        />
      </div>
      <small>Mehrfachnennungen möglich</small>
    </div>
    <div *ngIf="showIncomeInfoBox" class="alert alert-info" role="alert">
      Durchschnittliches Monatsnettoeinkommen (inkl. regelmäßiger Zulagen und
      Überstunden, ohne AMS Bezüge oder sonstige Einkommen) der letzten drei
      Monate. Waisenpension und Stipendien zählen ebenfalls zum Einkommen.
    </div>
    <div
      [ngClass]="{
        'border border-primary p-3 mb-3 rounded border-3': workingIncomeBorder
      }"
    >
      <div class="form-check" [ngClass]="{ 'mb-3': !workingIncomeBorder }">
        <label for="workingIncomeControl" class="form-check-label"
          >Einkünfte aus unselbstständiger Erwerbstätigkeit /
          Pensionszahlungen</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="workingIncomeControl"
          formControlName="workingIncome"
        />
      </div>
      <div *ngIf="showWorkingIncomeAmount" class="mb-3">
        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <label for="workingIncomeAmountControl">Betrag: *</label>
          </div>
          <div class="col">
            <input
              currencyMask
              onpaste="return false;"
              autocomplete="off"
              class="form-control"
              id="workingIncomeAmountControl"
              formControlName="workingIncomeAmount"
              [options]="{
                suffix: ' €',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: '.'
              }"
            />
          </div>
        </div>
        <div
          *ngIf="
            financialInfoForm.controls['workingIncomeAmount'].errors &&
            financialInfoForm.controls['workingIncomeAmount'].touched
          "
          class="alert alert-danger mt-2"
          role="alert"
        >
          Bitte einen Betrag eingeben
        </div>
      </div>
    </div>

    <div
      [ngClass]="{
        'border border-primary p-3 mb-3 rounded border-3': selfEmployedBorder
      }"
    >
      <div class="form-check" [ngClass]="{ 'mb-3': !selfEmployedBorder }">
        <label for="selfEmployedIncomeControl" class="form-check-label"
          >Einkünfte aus selbstständiger Erwerbstätigkeit</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="selfEmployedIncomeControl"
          formControlName="selfEmployedIncome"
        />
      </div>
      <div *ngIf="shwoSelfEmployedIncomeAmount" class="mb-3">
        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <label for="selfEmployedIncomeAmountControl">Betrag: *</label>
          </div>
          <div class="col">
            <input
              currencyMask
              onpaste="return false;"
              autocomplete="off"
              class="form-control"
              id="selfEmployedIncomeAmountControl"
              formControlName="selfEmployedIncomeAmount"
              [options]="{
                suffix: ' €',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: '.'
              }"
            />
          </div>
        </div>
        <div
          *ngIf="
            financialInfoForm.controls['selfEmployedIncomeAmount'].errors &&
            financialInfoForm.controls['selfEmployedIncomeAmount'].touched
          "
          class="alert alert-danger mt-2"
          role="alert"
        >
          Bitte einen Betrag eingeben
        </div>
      </div>
    </div>

    <div
      [ngClass]="{
        'border border-primary p-3 mb-3 rounded border-3': stateIncomeBorder
      }"
    >
      <div class="form-check" [ngClass]="{ 'mb-3': !stateIncomeBorder }">
        <label for="stateIncomeControl" class="form-check-label"
          >Einkünfte aus staatlichen Beihilfen</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="stateIncomeControl"
          formControlName="stateIncome"
        />
      </div>
      <div *ngIf="showStateIncomeAmount" class="mb-3">
        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <label for="stateIncomeAmountControl">Betrag: *</label>
          </div>
          <div class="col">
            <input
              currencyMask
              onpaste="return false;"
              autocomplete="off"
              class="form-control"
              id="stateIncomeAmountControl"
              formControlName="stateIncomeAmount"
              [options]="{
                suffix: ' €',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: '.'
              }"
            />
          </div>
        </div>
        <div
          *ngIf="
            financialInfoForm.controls['stateIncomeAmount'].errors &&
            financialInfoForm.controls['stateIncomeAmount'].touched
          "
          class="alert alert-danger mt-2"
          role="alert"
        >
          Bitte einen Betrag eingeben
        </div>

        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <label for="stateIncomeTextControl" class="form-label"
              >Art: *</label
            >
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="stateIncomeTextControl"
              placeholder="Bitte die Art der staatlichen Einkünfte eingeben"
              formControlName="stateIncomeText"
            />
          </div>
        </div>

        <div
          *ngIf="
            financialInfoForm.controls['stateIncomeText'].errors &&
            financialInfoForm.controls['stateIncomeText'].touched
          "
          class="alert alert-danger mt-2"
          role="alert"
        >
          Bitte die Art der staatlichen Einkünfte eingeben
        </div>
      </div>
    </div>

    <div class="mb-3 form-check">
      <label for="ongoingIncomeControl" class="form-check-label"
        >Laufendes Einkommen aus Erträgnissen / Dividenden /
        Mieteinnahmen</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="ongoingIncomeControl"
        formControlName="ongoingIncome"
      />
    </div>
    <div *ngIf="showOngoingIncomeAmount" class="mb-3">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="ongoingIncomeAmountControl">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="ongoingIncomeAmountControl"
            formControlName="ongoingIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['ongoingIncomeAmount'].errors &&
          financialInfoForm.controls['ongoingIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>
    </div>

    <div
      [ngClass]="{
        'border border-primary p-3 mb-3 rounded border-3':
          apprenticeIncomeBorder
      }"
    >
      <div class="form-check" [ngClass]="{ 'mb-3': !apprenticeIncomeBorder }">
        <label for="apprenticeIncomeControl" class="form-check-label"
          >Einkünfte aus Lehrlingsentschädigung</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="apprenticeIncomeControl"
          formControlName="apprenticeIncome"
        />
      </div>
      <div *ngIf="showApprenticeIncomeAmount" class="mb-3">
        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <label for="apprenticeIncomeAmountControl">Betrag: *</label>
          </div>
          <div class="col">
            <input
              currencyMask
              onpaste="return false;"
              autocomplete="off"
              class="form-control"
              id="apprenticeIncomeAmountControl"
              formControlName="apprenticeIncomeAmount"
              [options]="{
                suffix: ' €',
                prefix: '',
                align: 'left',
                precision: 0,
                allowNegative: false,
                thousands: '.'
              }"
            />
          </div>
        </div>
        <div
          *ngIf="
            financialInfoForm.controls['apprenticeIncomeAmount'].errors &&
            financialInfoForm.controls['apprenticeIncomeAmount'].touched
          "
          class="alert alert-danger mt-2"
          role="alert"
        >
          Bitte einen Betrag eingeben
        </div>
      </div>
    </div>

    <div class="mb-3 form-check">
      <label for="spendingIncomeControl" class="form-check-label"
        >Taschengeld</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="spendingIncomeControl"
        formControlName="spendingIncome"
      />
    </div>
    <div *ngIf="showSpendingIncomeAmount" class="mb-3">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="spendingIncomeAmountControl">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="spendingIncomeAmountControl"
            formControlName="spendingIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['spendingIncomeAmount'].errors &&
          financialInfoForm.controls['spendingIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>
    </div>
    <div class="mb-3 form-check">
      <label for="inheritIncomeControl" class="form-check-label"
        >Erbschaft / Schenkung</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="inheritIncomeControl"
        formControlName="inheritIncome"
      />
    </div>
    <div *ngIf="showInheritIncomeAmount" class="mb-3">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="inheritIncomeAmountControl">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="inheritIncomeAmountControl"
            formControlName="inheritIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['inheritIncomeAmount'].errors &&
          financialInfoForm.controls['inheritIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>
    </div>
    <div class="mb-3 form-check">
      <label for="saleIncomeControl" class="form-check-label"
        >Verkauf von Vermögenswerten (z.B. Immobilien, Wertpapieren,
        Beteiligungen)</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="saleIncomeControl"
        formControlName="saleIncome"
      />
    </div>
    <div *ngIf="showSaleIncomeAmount" class="mb-3">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="saleIncomeAmountControl">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="saleIncomeAmountControl"
            formControlName="saleIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['saleIncomeAmount'].errors &&
          financialInfoForm.controls['saleIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>
    </div>
    <div class="mb-3 form-check">
      <label for="insuranceIncomeControl" class="form-check-label"
        >Ablauf von Versicherungen / Verträgen</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="insuranceIncomeControl"
        formControlName="insuranceIncome"
      />
    </div>
    <div *ngIf="showInsuranceIncomeAmount" class="mb-3">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="insuranceIncomeAmount">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="insuranceIncomeAmount"
            formControlName="insuranceIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['insuranceIncomeAmount'].errors &&
          financialInfoForm.controls['insuranceIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>
    </div>
    <div class="mb-3 form-check">
      <label for="otherIncomeControl" class="form-check-label"
        >Sonstiges - bitte um Angaben</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="otherIncomeControl"
        formControlName="otherIncome"
      />
    </div>
    <div class="mb-3" *ngIf="showOtherIncome">
      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="otherIncomeAmountControl">Betrag: *</label>
        </div>
        <div class="col">
          <input
            currencyMask
            onpaste="return false;"
            autocomplete="off"
            class="form-control"
            id="otherIncomeAmountControl"
            formControlName="otherIncomeAmount"
            [options]="{
              suffix: ' €',
              prefix: '',
              align: 'left',
              precision: 0,
              allowNegative: false,
              thousands: '.'
            }"
          />
        </div>
      </div>
      <div
        *ngIf="
          financialInfoForm.controls['otherIncomeAmount'].errors &&
          financialInfoForm.controls['otherIncomeAmount'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte einen Betrag eingeben
      </div>

      <div class="row align-items-center mt-3">
        <div class="col-auto">
          <label for="otherIncomeTextControl" class="form-label">Art: *</label>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            id="otherIncomeTextControl"
            placeholder="Bitte die sonstigen Einkünfte eingeben"
            formControlName="otherIncomeText"
          />
        </div>
      </div>

      <div
        *ngIf="
          financialInfoForm.controls['otherIncomeText'].errors &&
          financialInfoForm.controls['otherIncomeText'].touched
        "
        class="alert alert-danger mt-2"
        role="alert"
      >
        Bitte die sonstigen Einkünfte eingeben
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="financialInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
