import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import * as banksFromJson from '../../../assets/data/blz.json';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import {
  isAfter,
  isBefore,
  startsNotWithZero,
  validIban,
} from 'src/app/validators/CustomValidator';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { FormUtils } from 'src/app/utils/FormUtil';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { Additional } from 'src/app/dtos/Additional';
import { PaybackMethod } from 'src/app/dtos/PaybackMethod';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.scss'],
})
export class BankInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Emitter(BankInfoState.updateBankInfo)
  updateBankInfo$!: Emittable<BankInfoRequest>;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(BankInfoState.getBankInfo)
  getBankInfo$!: Observable<BankInfoRequest>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;

  @Select(PaymentInfoState.getPaymentInfo)
  getPaymentInfo$!: Observable<PaymentInfoRequest>;

  partnerFlow: boolean = false;
  partialPayment: boolean = false;

  backendError: boolean = false;
  bankInfoForm: FormGroup = {} as FormGroup;
  patchApplicationRequest: PatchApplicationRequest = {};
  bankInfoRequest: BankInfoRequest = {};
  banks: any[] = (banksFromJson as any).default;
  showIban: boolean = false;
  showAccountSince: boolean = false;
  showAccountOk: boolean = false;
  showBlz: boolean = false;
  showOtherSettlementAccounts: boolean = false;
  showFirstName: boolean = false;
  showLastName: boolean = false;
  showBirthday: boolean = false;
  showCardNumber: boolean = false;
  showEmail: boolean = false;
  showMobileCountryCode: boolean = false;
  showMobileNumber: boolean = false;
  bankName: string = '';
  showNumberInfo: boolean = false;
  today: Date = new Date();
  showOkInfo: boolean = false;
  submitText: string = 'Weiter';
  earliestDate: Date = new Date('1900-01-01');
  latestPossibleBirthday: Date = new Date();
  countries: any = (countryDataFromJson as any).default;
  countryList: any[] = [];

  ngOnInit(): void {
    let helper: any[] = this.countries;
    this.countryList = helper.map((i) => {
      i.displayName = i.telPrefix + ' (' + i.country + ')';

      return i;
    });
    this.latestPossibleBirthday.setFullYear(
      this.latestPossibleBirthday.getFullYear() - 18
    );
    this.banks.sort((a, b) => a.Bankleitzahl - b.Bankleitzahl);
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.BANK_INOF;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getSelectedProduct$.subscribe((value) => {
      if (value.additional == Additional.PARTNER) {
        this.partnerFlow = true;
      }
    });

    this.getPaymentInfo$.subscribe((value) => {
      if (value.paybackMethod == PaybackMethod.PARTIAL_PAYMENT) {
        this.partialPayment = true;
      }
    });

    this.bankInfoForm = this.formBuilder.group({
      otherSettlementAccount: [null, []],
      iban: [null, []],
      blz: [null, []],
      accountSince: [null, []],
      accountOk: [null, []],
      firstName: [null, []],
      lastName: [null, []],
      birthday: [null, []],
      cardNumber: [null, []],
      email: [null, []],
      mobileCountryCode: [null, []],
      mobileNumber: [null, []],
    });

    this.bankInfoForm.controls['iban'].valueChanges.subscribe((value) => {
      if (value != null) {
        const iban = this.bankInfoForm.controls['iban'];
        value = value.replace(/\s+/g, '');
        iban.patchValue(value, { emitEvent: false });

        const blz = this.bankInfoForm.controls['blz'];
        blz.setValue(null);
        blz.updateValueAndValidity();

        if (value.length > 0) {
          let newValue = value.substring(4, 9);
          let helper: any[] = this.banks;

          helper.forEach((i) => {
            if (i.Bankleitzahl == newValue) {
              blz.setValue(i.Bankleitzahl);
              blz.updateValueAndValidity();
              return;
            }
          });
        }
      }
    });

    this.bankInfoForm.controls['blz'].valueChanges.subscribe((value) => {
      if (value !== null) {
        this.bankName = this.getBankNameFromBlz(value);
      } else {
        this.bankName = '';
      }
    });

    this.bankInfoForm.controls['otherSettlementAccount'].valueChanges.subscribe(
      (value) => {
        if (value == null || value == true) {
          this.renderIban(true);
          this.renderBlz(true);
          this.renderAccountSince(true);
          this.renderAccountOk(true);
        } else {
          this.renderIban(false);
          this.renderBlz(false);
          this.renderAccountSince(false);
          this.renderAccountOk(false);
          this.bankName = '';
        }
      }
    );

    this.getBankInfo$.subscribe((value) => {
      //Partner card
      if (this.partnerFlow) {
        this.renderFirstName(true);
        this.renderLastName(true);
        this.renderBirthday(true);
        this.renderCardNumber(true);
        this.renderEmail(true);
        this.renderMobileCountryCode(true);
        this.renderMobileNumber(true);
        this.patchPrimarCardDataFromStorage(value);

        //Partial Payment
        if (this.partialPayment) {
          this.renderOtherSettlementAccount(true);
          this.bankInfoForm.controls['otherSettlementAccount'].patchValue(true);
          this.bankInfoForm.controls['otherSettlementAccount'].disable();
          this.patchGiroFieldsFromStorage(value);
        }

        //No Partial Payment
        else {
          this.renderOtherSettlementAccount(true);
          if (value.otherSettlementAccount != undefined) {
            this.bankInfoForm.controls['otherSettlementAccount'].patchValue(
              value.otherSettlementAccount
            );

            if (value.otherSettlementAccount == true) {
              this.patchGiroFieldsFromStorage(value);
            }
          } else {
            this.bankInfoForm.controls['otherSettlementAccount'].patchValue(
              false
            );
          }
        }
      }

      //Primary Card
      else {
        this.renderOtherSettlementAccount(false);
        this.patchGiroFieldsFromStorage(value);
      }
    });
  }

  patchPrimarCardDataFromStorage(value: any): void {
    if (value.firstName != undefined) {
      this.bankInfoForm.controls['firstName'].patchValue(value.firstName);
    }
    if (value.lastName != undefined) {
      this.bankInfoForm.controls['lastName'].patchValue(value.lastName);
    }
    if (value.birthday != undefined) {
      this.bankInfoForm.controls['birthday'].patchValue(value.birthday);
    }
    if (value.cardNumber != undefined) {
      this.bankInfoForm.controls['cardNumber'].patchValue(value.cardNumber);
    }
    if (value.email != undefined) {
      this.bankInfoForm.controls['email'].patchValue(value.email);
    }
    if (value.mobileNumber != undefined) {
      let countryCode: string = this.getCountryCodeFromMobileNumber(
        value.mobileNumber
      );
      let numberSuffix: string = this.getNumberFromCountryCodeAndMobileNumber(
        countryCode,
        value.mobileNumber
      );

      this.bankInfoForm.controls['mobileCountryCode'].patchValue(countryCode);
      this.bankInfoForm.controls['mobileNumber'].patchValue(numberSuffix);
    }
  }

  patchGiroFieldsFromStorage(value: any): void {
    if (value.iban != undefined) {
      this.bankInfoForm.controls['iban'].patchValue(value.iban);
    }
    if (value.blz != undefined) {
      this.bankInfoForm.controls['blz'].patchValue(value.blz);
    }
    if (value.accountOk != undefined) {
      this.bankInfoForm.controls['accountOk'].patchValue(value.accountOk);
    }
    if (value.accountSince != undefined) {
      this.bankInfoForm.controls['accountSince'].patchValue(value.accountSince);
    }
  }

  onSubmit(): void {
    let bankNameForRequest: string = this.bankName;
    if (this.bankName.length > 120) {
      bankNameForRequest = this.bankName.substring(0, 120);
    }

    let mobileNumberForRequest = null;

    if (
      this.bankInfoForm.controls['mobileCountryCode'].value != null &&
      this.bankInfoForm.controls['mobileNumber'].value != null
    ) {
      mobileNumberForRequest =
        this.bankInfoForm.controls['mobileCountryCode'].value +
        this.bankInfoForm.controls['mobileNumber'].value;
    }

    this.bankInfoRequest = {
      otherSettlementAccount:
        this.bankInfoForm.controls['otherSettlementAccount'].value,
      iban: this.bankInfoForm.controls['iban'].value,
      bankName: FormUtils.handleClearInput(bankNameForRequest)!,
      blz: this.bankInfoForm.controls['blz'].value,
      accountOk: this.bankInfoForm.controls['accountOk'].value,
      accountSince: this.bankInfoForm.controls['accountSince'].value,
      firstName: this.bankInfoForm.controls['firstName'].value,
      lastName: this.bankInfoForm.controls['lastName'].value,
      birthday: this.bankInfoForm.controls['birthday'].value,
      cardNumber: this.bankInfoForm.controls['cardNumber'].value,
      email: this.bankInfoForm.controls['email'].value,
      mobileNumber: mobileNumberForRequest,
    };

    this.patchApplicationRequest = {
      bankInfo: this.bankInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateBankInfo$.emit(this.bankInfoRequest);

          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('service-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getBankNameFromBlz(blz: string): string {
    let helper: any[] = this.banks;
    let name: string = '';
    helper.forEach((i) => {
      if (i.Bankleitzahl == blz) {
        name = i.Bankenname;
        return;
      }
    });
    return name;
  }

  back(): void {
    this.router.navigateByUrl('product-selection');
  }

  renderOtherSettlementAccount(active: boolean): void {
    const otherSettlementAccount =
      this.bankInfoForm.controls['otherSettlementAccount'];
    this.showOtherSettlementAccounts = active;

    if (active) {
      otherSettlementAccount.setValidators([Validators.required]);
    } else {
      otherSettlementAccount.setValue(null);
      otherSettlementAccount.clearValidators();
    }

    otherSettlementAccount.updateValueAndValidity();
  }

  renderIban(active: boolean): void {
    const iban = this.bankInfoForm.controls['iban'];
    this.showIban = active;

    if (active) {
      iban.setValidators([
        Validators.required,
        Validators.pattern(`^AT[0-9]{18}$`),
        validIban(),
      ]);
    } else {
      iban.setValue(null);
      iban.clearValidators();
    }

    iban.updateValueAndValidity();
  }

  renderBlz(active: boolean): void {
    const blz = this.bankInfoForm.controls['blz'];
    this.showBlz = active;

    if (active) {
      blz.setValidators([Validators.required]);
    } else {
      blz.setValue(null);
      blz.clearValidators();
    }

    blz.updateValueAndValidity();
  }

  renderAccountSince(active: boolean): void {
    const accountSince = this.bankInfoForm.controls['accountSince'];
    this.showAccountSince = active;

    if (active) {
      accountSince.setValidators([
        Validators.required,
        isBefore(this.today),
        isAfter(this.earliestDate),
      ]);
    } else {
      accountSince.setValue(null);
      accountSince.clearValidators();
    }
    accountSince.updateValueAndValidity();
  }

  renderAccountOk(active: boolean): void {
    const accountOk = this.bankInfoForm.controls['accountOk'];
    this.showAccountOk = active;

    if (active) {
      accountOk.setValidators([Validators.required]);
    } else {
      accountOk.setValue(null);
      accountOk.clearValidators();
    }
    accountOk.updateValueAndValidity();
  }

  renderFirstName(active: boolean): void {
    const firstName = this.bankInfoForm.controls['firstName'];
    this.showFirstName = active;

    if (active) {
      firstName.setValidators([
        Validators.required,
        Validators.maxLength(26),
        Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
      ]);
    } else {
      firstName.setValue(null);
      firstName.clearValidators();
    }
    firstName.updateValueAndValidity();
  }

  renderEmail(active: boolean): void {
    const email = this.bankInfoForm.controls['email'];
    this.showEmail = active;

    if (active) {
      email.setValidators([
        Validators.required,
        Validators.email,
        Validators.maxLength(40),
      ]);
    } else {
      email.setValue(null);
      email.clearValidators();
    }
    email.updateValueAndValidity();
  }

  renderLastName(active: boolean): void {
    const lastName = this.bankInfoForm.controls['lastName'];
    this.showLastName = active;

    if (active) {
      lastName.setValidators([
        Validators.required,
        Validators.maxLength(26),
        Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
      ]);
    } else {
      lastName.setValue(null);
      lastName.clearValidators();
    }
    lastName.updateValueAndValidity();
  }

  renderCardNumber(active: boolean): void {
    const cardNumber = this.bankInfoForm.controls['cardNumber'];
    this.showCardNumber = active;

    if (active) {
      cardNumber.setValidators([
        Validators.required,
        Validators.pattern(`^[0-9]{16}$`),
      ]);
    } else {
      cardNumber.setValue(null);
      cardNumber.clearValidators();
    }
    cardNumber.updateValueAndValidity();
  }

  renderBirthday(active: boolean): void {
    const birthday = this.bankInfoForm.controls['birthday'];
    this.showBirthday = active;

    if (active) {
      birthday.setValidators([
        Validators.required,
        isBefore(this.latestPossibleBirthday),
        isAfter(this.earliestDate),
      ]);
    } else {
      birthday.setValue(null);
      birthday.clearValidators();
    }
    birthday.updateValueAndValidity();
  }

  renderMobileNumber(active: boolean): void {
    const mobileNumber = this.bankInfoForm.controls['mobileNumber'];
    this.showMobileNumber = active;

    if (active) {
      mobileNumber.setValidators([
        Validators.required,
        Validators.pattern(`^[0-9]{4,14}$`),
        startsNotWithZero(),
      ]);
    } else {
      mobileNumber.setValue(null);
      mobileNumber.clearValidators();
    }
    mobileNumber.updateValueAndValidity();
  }

  renderMobileCountryCode(active: boolean): void {
    const mobileCountryCode = this.bankInfoForm.controls['mobileCountryCode'];
    this.showMobileCountryCode = active;

    if (active) {
      mobileCountryCode.setValidators([Validators.required]);
    } else {
      mobileCountryCode.setValue(null);
      mobileCountryCode.clearValidators();
    }
    mobileCountryCode.updateValueAndValidity();
  }

  getCountryCodeFromMobileNumber(mobileNumber: string): string {
    let helper: any[] = this.countries;
    let countryCode: string = '';
    helper.forEach((i) => {
      if (mobileNumber.startsWith(i.telPrefix)) {
        countryCode = i.telPrefix;
        return;
      }
    });
    return countryCode;
  }

  getNumberFromCountryCodeAndMobileNumber(
    countryCode: string,
    mobileNumber: string
  ): string {
    return mobileNumber.substring(countryCode.length);
  }
}
