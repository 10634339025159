import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import * as zipFromJson from '../../../assets/data/zip.json';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';
import * as livingCountriesFromJson from '../../../assets/data/living_country_iso_code.json';
import { startsNotWithZero } from 'src/app/validators/CustomValidator';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { AtAddress } from 'src/app/dtos/AtAddress';
import { CorAddress } from 'src/app/dtos/CorAddress';
import { FormUtils } from 'src/app/utils/FormUtil';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { Select } from '@ngxs/store';
import {
  Observable,
  Subject,
  catchError,
  concat,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap,
  zip,
} from 'rxjs';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;

  @Emitter(ContactInfoState.updateContactInfo)
  updateContactInfo$!: Emittable<ContactInfoRequest>;

  @Select(ContactInfoState.getContactInfo)
  getContactInfo$!: Observable<ContactInfoRequest>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  showAdrressLine1Info: boolean = false;
  shoEmailInfo: boolean = false;
  showCorInfo: boolean = false;
  showNumberInfo: boolean = false;
  showLivingInAt: boolean = false;
  showLivingNotInAt: boolean = false;
  showCorInAt: boolean = false;
  showCorNotInAt: boolean = false;

  streetInput$ = new Subject<string>();
  streetLoading: boolean = false;
  streetList: Observable<any> | undefined;

  corStreetInput$ = new Subject<string>();
  corStreetLoading: boolean = false;
  corStreetList: Observable<any> | undefined;

  contactInfoForm: FormGroup = {} as FormGroup;
  backendError: boolean = false;
  zips: any = (zipFromJson as any).default;
  countries: any = (countryDataFromJson as any).default;
  livingCountries: any = (livingCountriesFromJson as any).default;
  countryList: any[] = [];

  showCorrespondence: boolean = false;
  contactInfoRequest: ContactInfoRequest = {};
  patchApplicationRequest: PatchApplicationRequest = {};
  currentApplication: ApplicationResponse = {};
  submitText: string = 'Weiter';

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.CONTACT_INFO;
    this.updateAppState$.emit(this.appState);

    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }

    let helper: any[] = this.countries;
    this.countryList = helper.map((i) => {
      i.displayName = i.telPrefix + ' (' + i.country + ')';

      return i;
    });

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.contactInfoForm = this.formBuilder.group({
      livingCountry: [null, [Validators.required]],
      street: [null, []],
      houseNumber: [null, []],
      addressLine1: [null, []],
      addressLine2: [null, []],
      zipAT: [null, []],
      zipNonAT: [null, []],
      place: [
        null,
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.pattern(`^[A-Za-zÀ-ž∂\u0370-\u03FF ,.'-]+$`),
        ],
      ],
      mobileCountryCode: [null, [Validators.required]],
      mobileNumber: [
        null,
        [
          Validators.required,
          Validators.pattern(`^[0-9]{4,14}$`),
          startsNotWithZero(),
        ],
      ],
      email: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(40)],
      ],
      sendToCorrespondence: [false, []],
      corStreet: [null, []],
      corHouseNumber: [null, []],
      corAddressLine1: [null, []],
      corAddressLine2: [null, []],
      corPlace: [null, []],
      corCountry: [null, []],
      corZipAT: [null, []],
      corZipNonAT: [null, []],
    });

    this.streetList = concat(
      of([]), // default items
      this.streetInput$.pipe(
        filter((res: any) => {
          return res !== null && res.length >= 2;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.streetLoading = true)),
        switchMap((term: any) => {
          return this.api.searchAddress(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.streetLoading = false))
          );
        })
      )
    );

    this.corStreetList = concat(
      of([]), // default items
      this.corStreetInput$.pipe(
        filter((res: any) => {
          return res !== null && res.length >= 2;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.corStreetLoading = true)),
        switchMap((term: any) => {
          return this.api.searchAddress(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.corStreetLoading = false))
          );
        })
      )
    );

    this.contactInfoForm.controls['street'].valueChanges.subscribe((value) => {
      const zipAT = this.contactInfoForm.controls['zipAT'];
      const place = this.contactInfoForm.controls['place'];

      if (value === null) {
        zipAT.patchValue(null);
        place.patchValue(null);
      } else {
        zipAT.patchValue(value.zip);

        if (value.city !== undefined && value.city !== null && value.city.length > 25) {
          value.city = value.city.substring(0, 25);
        }
        place.patchValue(value.city);
      }
    });

    this.contactInfoForm.controls['corStreet'].valueChanges.subscribe(
      (value) => {
        const corZipAt = this.contactInfoForm.controls['corZipAT'];
        const corPlace = this.contactInfoForm.controls['corPlace'];

        if (value === null) {
          corZipAt.patchValue(null);
          corPlace.patchValue(null);
        } else {
          corZipAt.patchValue(value.zip);

          if (value.city !== undefined && value.city !== null && value.city.length > 25) {
            value.city = value.city.substring(0, 25);
          }
          corPlace.patchValue(value.city);
        }
      }
    );

    this.contactInfoForm.controls['livingCountry'].valueChanges.subscribe(
      (value) => {
        const zipAT = this.contactInfoForm.controls['zipAT'];
        const zipNonAT = this.contactInfoForm.controls['zipNonAT'];
        const addressLine1 = this.contactInfoForm.controls['addressLine1'];
        const addressLine2 = this.contactInfoForm.controls['addressLine2'];
        const houseNumber = this.contactInfoForm.controls['houseNumber'];
        const street = this.contactInfoForm.controls['street'];

        zipAT.clearValidators();
        zipAT.setValue(null);
        zipNonAT.clearValidators();
        zipNonAT.setValue(null);
        addressLine1.clearValidators();
        addressLine1.setValue(null);
        addressLine2.clearValidators();
        addressLine2.setValue(null);
        houseNumber.clearValidators();
        houseNumber.setValue(null);
        street.clearValidators();
        street.setValue(null);

        if (value === 'AT') {
          this.showLivingInAt = true;
          this.showLivingNotInAt = false;
          zipAT.setValidators([Validators.required]);
          houseNumber.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-z /-]+$`),
            Validators.maxLength(10),
          ]);
          street.setValidators([Validators.required]);
        } else if (value === null) {
          this.showLivingInAt = false;
          this.showLivingNotInAt = false;
        } else {
          this.showLivingInAt = false;
          this.showLivingNotInAt = true;
          zipNonAT.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(9),
          ]);
          addressLine1.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          addressLine2.setValidators([
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
        }

        zipAT.updateValueAndValidity();
        zipNonAT.updateValueAndValidity();
        addressLine1.updateValueAndValidity();
        addressLine2.updateValueAndValidity();
        houseNumber.updateValueAndValidity();
        street.updateValueAndValidity();
      }
    );

    /*     this.contactInfoForm.controls['zipAT'].valueChanges.subscribe((value) => {
      const place = this.contactInfoForm.controls['place'];

      if (value === null) {
        place.setValue(null);
      } else {
        let placeFromObject: string = this.getPlaceFromZip(value);

        if (placeFromObject.length > 25) {
          placeFromObject = placeFromObject.substring(0, 25);
        }
        place.setValue(placeFromObject);
      }
      place.updateValueAndValidity();
    });

    this.contactInfoForm.controls['corZipAT'].valueChanges.subscribe(
      (value) => {
        const corPlace = this.contactInfoForm.controls['corPlace'];

        if (value === null) {
          corPlace.setValue(null);
        } else {
          let placeFromObject: string = this.getPlaceFromZip(value);

          if (placeFromObject.length > 25) {
            placeFromObject = placeFromObject.substring(0, 25);
          }
          corPlace.setValue(placeFromObject);
        }
        corPlace.updateValueAndValidity();
      }
    ); */

    this.contactInfoForm.controls['corCountry'].valueChanges.subscribe(
      (value) => {
        const corZipNonAT = this.contactInfoForm.controls['corZipNonAT'];
        const corZipAT = this.contactInfoForm.controls['corZipAT'];
        const corAddressLine1 =
          this.contactInfoForm.controls['corAddressLine1'];
        const corAddressLine2 =
          this.contactInfoForm.controls['corAddressLine2'];
        const corHouseNumber = this.contactInfoForm.controls['corHouseNumber'];
        const corStreet = this.contactInfoForm.controls['corStreet'];

        corZipNonAT.clearValidators();
        corZipNonAT.setValue(null);
        corZipAT.clearValidators();
        corZipAT.setValue(null);
        corAddressLine1.clearValidators();
        corAddressLine1.setValue(null);
        corAddressLine2.clearValidators();
        corAddressLine2.setValue(null);
        corHouseNumber.clearValidators();
        corHouseNumber.setValue(null);
        corStreet.clearValidators();
        corStreet.setValue(null);

        if (value == 'AT') {
          this.showCorInAt = true;
          this.showCorNotInAt = false;
          corZipAT.setValidators([Validators.required]);
          corStreet.setValidators([Validators.required]);
          corHouseNumber.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-z /-]+$`),
            Validators.maxLength(10),
          ]);
        } else if (value === null) {
          this.showCorInAt = false;
          this.showCorNotInAt = false;
        } else {
          this.showCorInAt = false;
          this.showCorNotInAt = true;
          corZipNonAT.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(9),
          ]);
          corAddressLine1.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          corAddressLine2.setValidators([
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
        }

        corZipAT.updateValueAndValidity();
        corZipNonAT.updateValueAndValidity();
        corStreet.updateValueAndValidity();
        corHouseNumber.updateValueAndValidity();
        corAddressLine1.updateValueAndValidity();
        corAddressLine2.updateValueAndValidity();
      }
    );

    this.contactInfoForm.controls[
      'sendToCorrespondence'
    ].valueChanges.subscribe((value) => {
      const corPlace = this.contactInfoForm.controls['corPlace'];
      const corCountry = this.contactInfoForm.controls['corCountry'];
      corPlace.clearValidators();
      corPlace.setValue(null);
      corCountry.clearValidators();
      corCountry.setValue(null);

      if (value) {
        this.showCorrespondence = true;
        corPlace.setValidators([
          Validators.maxLength(25),
          Validators.pattern(`^[A-Za-zÀ-ž∂\u0370-\u03FF ,.'-]+$`),
          Validators.required,
        ]);
        corCountry.setValidators([Validators.required]);
      } else {
        this.showCorrespondence = false;
      }

      corPlace.updateValueAndValidity();
      corCountry.updateValueAndValidity();
    });

    this.getContactInfo$.subscribe((value) => {
      if (value.livingCountry != undefined) {
        this.contactInfoForm.controls['livingCountry'].patchValue(
          value.livingCountry
        );

        if (value.livingCountry == 'AT' && value.street != undefined) {
          const json = {
            street: value.street,
          };
          this.contactInfoForm.controls['street'].patchValue(json);
        }

        if (value.livingCountry == 'AT' && value.zip != undefined) {
          this.contactInfoForm.controls['zipAT'].patchValue(value.zip);
        }

        if (value.livingCountry == 'AT' && value.houseNumber != undefined) {
          this.contactInfoForm.controls['houseNumber'].patchValue(
            value.houseNumber
          );
        }

        if (value.livingCountry != 'AT' && value.zip != undefined) {
          this.contactInfoForm.controls['zipNonAT'].patchValue(value.zip);
        }

        if (value.livingCountry != 'AT' && value.line1 != undefined) {
          this.contactInfoForm.controls['addressLine1'].patchValue(value.line1);
        }
        if (value.livingCountry != 'AT' && value.line2 != undefined) {
          this.contactInfoForm.controls['addressLine2'].patchValue(value.line2);
        }
      }

      if (value.place != undefined) {
        this.contactInfoForm.controls['place'].patchValue(value.place);
      }

      if (value.email != undefined) {
        this.contactInfoForm.controls['email'].patchValue(value.email);
      }

      if (value.phoneNumber !== undefined) {
        let countryCode: string = this.getCountryCodeFromMobileNumber(
          value.phoneNumber
        );
        let numberSuffix: string = this.getNumberFromCountryCodeAndMobileNumber(
          countryCode,
          value.phoneNumber
        );

        this.contactInfoForm.controls['mobileCountryCode'].patchValue(
          countryCode
        );
        this.contactInfoForm.controls['mobileNumber'].patchValue(numberSuffix);
      }

      if (value.sendToCorrespondence != undefined) {
        this.contactInfoForm.controls['sendToCorrespondence'].patchValue(
          value.sendToCorrespondence
        );
      }

      if (value.corCountry != undefined) {
        this.contactInfoForm.controls['corCountry'].patchValue(
          value.corCountry
        );

        if (value.corCountry == 'AT' && value.corStreet != undefined) {
          const json = {
            street: value.corStreet,
          };
          this.contactInfoForm.controls['corStreet'].patchValue(json);
        }

        if (value.corCountry == 'AT' && value.corZip != undefined) {
          this.contactInfoForm.controls['corZipAT'].patchValue(value.corZip);
        }

        if (value.corCountry == 'AT' && value.corHouseNumber != undefined) {
          this.contactInfoForm.controls['corHouseNumber'].patchValue(
            value.corHouseNumber
          );
        }

        if (value.corCountry != 'AT' && value.corZip != undefined) {
          this.contactInfoForm.controls['corZipNonAT'].patchValue(value.corZip);
        }

        if (value.corCountry != 'AT' && value.corLine1 != undefined) {
          this.contactInfoForm.controls['corAddressLine1'].patchValue(
            value.corLine1
          );
        }
        if (value.corCountry != 'AT' && value.corLine2 != undefined) {
          this.contactInfoForm.controls['corAddressLine2'].patchValue(
            value.corLine2
          );
        }
      }

      if (value.corPlace != undefined) {
        this.contactInfoForm.controls['corPlace'].patchValue(value.corPlace);
      }
    });
  }

  onSubmit(): void {
    let zipForBackend: string = '';
    let streetForBackend = null;
    let corStreetForBackend = null;

    if (this.showLivingInAt) {
      zipForBackend = this.contactInfoForm.controls['zipAT'].value;
      streetForBackend = this.contactInfoForm.controls['street'].value.street;
    } else {
      zipForBackend = this.contactInfoForm.controls['zipNonAT'].value;
    }

    let corZipForBackend: string = '';

    if (this.showCorInAt) {
      corZipForBackend = this.contactInfoForm.controls['corZipAT'].value;
      corStreetForBackend =
        this.contactInfoForm.controls['corStreet'].value.street;
    } else {
      corZipForBackend = this.contactInfoForm.controls['corZipNonAT'].value;
    }

    this.contactInfoRequest = {
      livingCountry: this.contactInfoForm.controls['livingCountry'].value,
      line1: this.contactInfoForm.controls['addressLine1'].value,
      line2: FormUtils.handleClearInput(
        this.contactInfoForm.controls['addressLine2'].value
      )!,
      zip: zipForBackend,
      houseNumber: this.contactInfoForm.controls['houseNumber'].value,
      street: streetForBackend,
      place: this.contactInfoForm.controls['place'].value,
      corLine1: this.contactInfoForm.controls['corAddressLine1'].value,
      corLine2: FormUtils.handleClearInput(
        this.contactInfoForm.controls['corAddressLine2'].value
      )!,
      corHouseNumber: this.contactInfoForm.controls['corHouseNumber'].value,
      corStreet: corStreetForBackend,
      corZip: corZipForBackend,
      corPlace: this.contactInfoForm.controls['corPlace'].value,
      corCountry: this.contactInfoForm.controls['corCountry'].value,
      sendToCorrespondence:
        this.contactInfoForm.controls['sendToCorrespondence'].value,
      email: this.contactInfoForm.controls['email'].value,
      phoneNumber:
        this.contactInfoForm.controls['mobileCountryCode'].value +
        this.contactInfoForm.controls['mobileNumber'].value,
    };

    this.patchApplicationRequest = {
      contactInfo: this.contactInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateContactInfo$.emit(this.contactInfoRequest);

          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('legitimation-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getPlaceFromZip(zip: string): string {
    let helper: any[] = this.zips;
    let place: string = '';
    helper.forEach((i) => {
      if (i.plz == zip) {
        place = i.ort;
        return;
      }
    });
    return place;
  }

  getCountryCodeFromMobileNumber(mobileNumber: string): string {
    let helper: any[] = this.countries;
    let countryCode: string = '';
    helper.forEach((i) => {
      if (mobileNumber.startsWith(i.telPrefix)) {
        countryCode = i.telPrefix;
        return;
      }
    });
    return countryCode;
  }

  getNumberFromCountryCodeAndMobileNumber(
    countryCode: string,
    mobileNumber: string
  ): string {
    return mobileNumber.substring(countryCode.length);
  }

  back(): void {
    this.router.navigateByUrl('personal-info');
  }
}
