<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div class="mb-3">
    <b>Zeichnung Hauptkarteninhaber</b>
  </div>

  <form [formGroup]="signingForm" (ngSubmit)="onSubmit()">
    <div class="mb-3 form-check">
      <label for="acceptRemoteControl" class="form-check-label"
        >Ja, ich stimme der Erbringung von Bankgeschäften unter Verwendung von
        Fernkommunikationsmitteln zu und vereinbare diese Art der Erbringung mit
        der BAWAG.</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="acceptRemoteControl"
        formControlName="acceotRemote"
      />
    </div>

    <div class="mb-3 form-check">
      <label for="acceptMTANControl" class="form-check-label"
        >Ich bin damit einverstanden, dass mir eine mobileTAN als
        Unterschriftsersatz zur Unterfertigung der oben angeführten Dokumente
        und der im Kreditkartenauftrag enthaltenen Erklärung zur Entbindung der
        BAWAG vom Bankgeheimnis auf meine bekanntgegebene Telefonnummer gesendet
        wird. Mit Eingabe dieser mobileTAN unterzeichne ich diese Dokumente
        sowie die Entbindung vom Bankgeheimnis; damit erkläre ich verbindlich
        mein Einverständnis mit der Geltung und mit dem Inhalt dieser
        Dokumente</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="acceptMTANControl"
        formControlName="acceptMTAN"
      />
    </div>

    <div class="mb-3">
      Für Informationen zum Datenschutz klicken Sie auf unsere
      <a
        target="_blank"
        class="link-offset-2 link-underline link-underline-opacity-0"
        href="https://www.paylife.at/linkableblob/-/437898/174b1ecab7c16d5009f7727e3a47e3d2/dsgvo-infoblatt---paylife-aut-pdf-data.pdf"
        ><b>Datenschutzbestimmungen</b></a
      >.
    </div>
    <div class="mb-3">
      <label for="codeControl" class="form-label">mobileTAN:</label>
      <input
        type="password"
        class="form-control"
        id="codeControl"
        placeholder="Bitte den mobileTAN eingeben"
        formControlName="code"
      />
      <div
        *ngIf="
          signingForm.controls['code'].errors &&
          signingForm.controls['code'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen validen mobileTAN eingeben
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="newCode()" class="btn btn-primary">
            Neu anfordern
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="signingForm.invalid"
            class="btn btn-primary"
          >
            Zeichnen
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
