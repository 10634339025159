import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { SignApplicationRequest } from 'src/app/dtos/SignApplicationRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import {
  CurrentErrorState,
  ErrorModel,
} from 'src/app/states/CurrentErrorState';

const errorMap = new Map([
  ['Primary Code is expired', 'Der Code ist bereits abgelaufen'],
  ['To many failed Primary Attempts', 'Zu viele Fehlversuche'],
  ['Invalid Primary Code', 'Code ungültig'],
]);

@Component({
  selector: 'app-primary-signing',
  templateUrl: './primary-signing.component.html',
  styleUrl: './primary-signing.component.scss',
})
export class PrimarySigningComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  backendError: boolean = false;
  errorMessage: string = '';

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;

  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  currentError: ErrorModel = {};

  @Emitter(CurrentErrorState.updateCurrentError)
  updateCurrentError$!: Emittable<ErrorModel>;

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};

  signingForm: FormGroup = {} as FormGroup;
  signApplicationRequest: SignApplicationRequest = {};

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.PRIMARY_SIGNING;
    this.updateAppState$.emit(this.appState);

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.getCurrentError$.subscribe((value) => (this.currentError = value));

    this.signingForm = this.formBuilder.group({
      code: [null, [Validators.required, Validators.pattern(`^[0-9]{5}$`)]],
      acceptMTAN: [false, [Validators.requiredTrue]],
      acceotRemote: [false, [Validators.requiredTrue]],
    });
  }

  onSubmit(): void {
    this.signApplicationRequest = {
      code: this.signingForm.controls['code'].value,
    };

    this.api
      .signApplication(
        this.currentApplication.applicationId!,
        this.signApplicationRequest,
        true
      )
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.router.navigateByUrl('result');
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
          const message = error.headers.get('x-bawagpsk-frontend-error');
          console.log(message);

          if (errorMap.has(message)) {
            this.errorMessage = errorMap.get(message)!;
          } else if (message === 'MAIL_SERVICE_NOT_AVAILABLE') {
            this.currentError.mailServiceFailed = true;
            this.updateCurrentError$.emit(this.currentError);
            this.router.navigateByUrl('result');
          } else {
            this.errorMessage = 'Es ist ein Fehler aufgetreten';
          }
        },
      });
  }

  newCode(): void {
    this.api
      .confirmApplication(this.currentApplication.applicationId!, true)
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.backendError = false;
        },
        error: (error) => {
          this.backendError = true;
          this.errorMessage = 'Es ist ein Fehler aufgetreten';
          console.log(error);
        },
      });
  }
}
